import React, {useState} from "react";
import {Box, LinearProgress} from "@mui/material";
import {PagiFilters, parsePagiFiltersFromURL} from "../../contexts/GenericProvider";
import {useSongRequests} from "../../contexts/SongRequestsProvider";
import SongRequestCard from "./SongRequestCard";
import LazyLoadWrapper from "../LazyLoadWrapper";


interface SongRequestListProps {
    event_id?: string | number;
}

const SongRequestList: React.FC<SongRequestListProps> = ({event_id}) => {
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const apiUrl = (event_id) ? `/api/song_requests/by-event/${event_id}?status=requested,accepted` : `/api/song_requests?status=requested,accepted`
    const {
        apiResponse,
        error,
        isLoading
    } = useSongRequests(apiUrl, pagination);

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }

    if (!apiResponse || (isLoading && !apiResponse?.results)) return <Box p={2}><LinearProgress/></Box>

    if (apiResponse.count === 0) return null;

    if (error) return <Box p={2}>{error}</Box>

    return (
        <LazyLoadWrapper
            onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
            hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
            threshold={50}
            isLoading={isLoading}
        >
            {apiResponse.results.map((entity) => <SongRequestCard entity={entity}
                                                                key={`${entity._type}-${entity.id}`}/>)}
        </LazyLoadWrapper>
    );
}

export default SongRequestList;
