import React, {ChangeEvent, useRef, useState} from 'react';
import {StyledBadge, UploadArea} from "../../forms/StyledFields";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import {Dialog, DialogContent, SvgIcon, Typography} from "@mui/material";
import {EditOutlined} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import CropperComponent from './CropperComponent'; // Import the new CropperComponent
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {ReactComponent as imageUploadIcon} from '../../assets/imageuploadicon.svg';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface Upload {
    id?: string;
    url: string;
    file?: Blob;
}

interface ImageUploadProps {
    field_name: string;
    index: number;
    selected: string;
    onSelect: (image: Upload, field_name: string, index: number) => void;
}


const ImageUpload: React.FC<ImageUploadProps> = ({onSelect, selected, index, field_name}) => {
    const [image, setImage] = useState<Upload | null>(selected ? {url: selected} : null);
    const [cropDialogOpen, setCropDialogOpen] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [transitionCompleted, setTransitionCompleted] = React.useState(false);

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files[0]) {
            const file = files[0];
            const url = URL.createObjectURL(file);
            setImage({url, file});
            setCropDialogOpen(true);
        }
    };

    const handleCropComplete = (url: string, file: Blob) => {
        console.log(`COMPLETE: ${field_name} - ${index} - ${url}`, file)
        setImage({url, file});
        onSelect({url, file}, field_name, index)
        setCropDialogOpen(false);
    };

    const handleCancel = () => {
        setImage(selected ? {url: selected} : null);
        setCropDialogOpen(false);
        setTransitionCompleted(false); // Reset transition state on close
    };

    const showEditModal = () => {
        if (!image || !image.url) {
            return handleIconClick()
        }
        setCropDialogOpen(true);
    };

    const handleIconClick = () => {
        fileInputRef.current?.click();
    };

    const Wrapper = image ? Grid : UploadArea;

    return (
        <Wrapper sx={{textAlign: 'center', backgroundColor: 'formBackground.main', borderRadius: '25px', height: '100%'}}>
            {cropDialogOpen &&
                <Dialog keepMounted={true}
                        open={cropDialogOpen}
                        onClose={() => setCropDialogOpen(false)}
                        TransitionComponent={Transition}
                        onTransitionEnd={() => setTransitionCompleted(true)}
                        fullScreen={true}>

                    <DialogContent sx={{backgroundColor: '#010101', position: 'relative'}}>
                        {!transitionCompleted || !image || !image.url ? 'rendering...' : (
                            <CropperComponent
                                imageSrc={image.url}
                                onDone={handleCropComplete}
                                onChangeImage={handleIconClick}
                                onCancel={handleCancel}
                            />
                        )}
                    </DialogContent>
                </Dialog>
            }

            <input
                accept="image/*"
                style={{display: 'none'}}
                id="icon-button-file"
                type="file"
                ref={fileInputRef}
                onChange={handleImageChange}
            />

            {image ? (
                <StyledBadge
                    overlap="circular"
                    onClick={handleIconClick}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    badgeContent={<EditOutlined color="secondary" fontSize={'small'}/>}
                    sx={{height: '486px', width: '100%'}}
                >
                    {field_name === 'profile_picture' ? (
                        <Avatar 
                            sx={{
                                width: 125, 
                                height: 125,
                                borderRadius: '25px',
                                '& img': {
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '100%'
                                }
                            }} 
                            alt={'preview'} 
                            src={image.url}
                        />
                    ) : (
                        <Avatar 
                            sx={{
                                width: '100%', 
                                height: '100%',
                                borderRadius: '25px',
                                '& img': {
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '100%'
                                }
                            }} 
                            alt={'preview'} 
                            variant={'rounded'} 
                            src={image.url}
                        />
                    )}
                </StyledBadge>
            ) : (
                // the way the icon button and typography are positioned is super weird here. Don't know whats going on. 
                <label htmlFor="icon-button-file">
                    <IconButton onClick={showEditModal} aria-label="upload picture">
                        {/* <AddCircleOutlineSharpIcon sx={{color: '#868484'}}/> */}
                        <SvgIcon component={imageUploadIcon} sx={{fontSize: '59px'}} inheritViewBox/>
                    </IconButton>
                    <Typography variant={'caption'} fontWeight={'bold'} style={{color: '#FFFFFF'}} fontSize={'20px'}>Upload an image</Typography>
                </label>
            )}
        </Wrapper>
    );
};

export default ImageUpload;
