import React, {createContext, ReactNode, useContext, useState} from 'react';
import {Events, Invites} from "../object-actions/types/types";
import ApiClient, {HttpResponse} from "../config/ApiClient";

interface FocusedContextType {
    error: string | null; // WARN: potential collisions between requests
    focusedEvent: Events | null;
    myInvite: Invites | null;
    setMyInvite: (invite:Invites | null) => void;
    setFocusedEvent: (event: Events | null) => void;
    fetchInviteCounts: (eid: number | string) => void;
    fetchInviteLink: (alias: string) => void;
    fetchEvent: (eid: number | string) => Promise<HttpResponse<Events>>
    inviteCounts: { [key: string]: number };
    setInviteCounts: (counts: { [key: string]: number }) => void;
}

const FocusedContext = createContext<FocusedContextType | undefined>(undefined);

interface FocusedProviderProps {
    children: ReactNode;
}

export const FocusedProvider: React.FC<FocusedProviderProps> = ({children}) => {
    const [error, setError] = useState<string | null>(null);
    const [focusedEvent, setFocusedEvent] = useState<Events | null>(null);
    const [myInvite, setMyInvite] = useState<Invites | null>(null);
    const [inviteCounts, setInviteCounts] = useState<{ [key: string]: number }>({});

    async function fetchInviteCounts(eid: number | string) {
        let apiUrl = `/api/invites/counts-by-event/${eid}`
        apiUrl += `?${window.location.search}`
        const response = await ApiClient.get(apiUrl);
        if (response.error) {
            setError(response.error)
        } else {
            setError(null)
            // @ts-ignore
            setInviteCounts(response.data)
        }
        return response
    }


    async function fetchEvent(eid: number | string) {
        let apiUrl = `${process.env.REACT_APP_API_HOST}/api`

        if (eid && parseInt(eid.toString()) > 0) {
            apiUrl += `/events/${eid}`
        } else {
            //
            apiUrl += `/invite_links/${eid}`
        }
        apiUrl += `?${window.location.search}`
        const response: HttpResponse<Events> = await ApiClient.get(apiUrl);
        if (response.error) {
            setError(response.error)
        } else {
            setError(null)
            setFocusedEvent(response.data as Events)
        }
        return response
    }

    async function fetchInviteLink(alias: string) {
        let apiUrl = `${process.env.REACT_APP_API_HOST}/api`
        apiUrl += `/invite_links/${alias}`
        apiUrl += `?${window.location.search}`
        const response: HttpResponse<any> = await ApiClient.get(apiUrl); // WARN: upserts invite with "seen" status
        if (response.error) {
            setError(response.error)
        } else {
            setError(null)
            setFocusedEvent(response.data?.event)
        }
        return response
    }

    return (
        <FocusedContext.Provider
            value={{
                error,
                focusedEvent,
                setFocusedEvent,
                myInvite,
                setMyInvite,
                inviteCounts,
                setInviteCounts,
                fetchInviteLink,
                fetchEvent,
                fetchInviteCounts
            }}
        >
            {children}
        </FocusedContext.Provider>
    );
};

// Custom hook for using the context
export const useFocusedContext = (): FocusedContextType => {
    const context = useContext(FocusedContext);
    if (!context) {
        throw new Error('useFocusedContext must be used within a FocusedProvider');
    }
    return context;
};
