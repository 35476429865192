import React, {useState} from 'react';
import {Box, LinearProgress, Typography} from "@mui/material";
import {Friendships} from "../object-actions/types/types";
import {useLocation, useNavigate} from "react-router-dom";
import FriendInvite from "./FriendInvite";
import {PagiFilters, parsePagiFiltersFromURL} from "../contexts/GenericProvider";
import ApiClient from "../config/ApiClient";
import {useAuth} from "../allauth/auth";
import {ButtonPill} from "../forms/StyledFields";
import {useSnackbar} from "notistack";
import LazyLoadWrapper from "./LazyLoadWrapper";
import { useFriends } from "../contexts/FriendsProvider";
import HorizontalFriendsToInviteList from './HorizontalFriendsToInviteList';

interface FriendSelectorProps {
    event_id: number | string;
}

const FriendSelector: React.FC<FriendSelectorProps> = ({event_id}) => {
    const location = useLocation();
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const [selectedFriends, setselectedFriends] = useState<{ [key: string | number]: string }>({});
    const [selectedFriendsFullData, setselectedFriendsFullData] = useState<any[]>([]);
    const [syncing, setSyncing] = useState<boolean>(false);
    const navigate = useNavigate();
    const me = useAuth()?.data?.user
    const {enqueueSnackbar} = useSnackbar()

    let apiUrl = `/api/friendships`
    const params = new URLSearchParams(location.search);
    params.set('status', 'accepted,pending')
    if (event_id) {
        params.set('event_id', event_id.toString())
    }
    apiUrl += `?${params.toString()}`

    const {
        apiResponse,
        error,
        isLoading,
        fetchList
    } = useFriends(apiUrl, pagination);

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }

    const handleSelect = (id: number | string, has_friendship:boolean) => {
        setselectedFriends(prev => {
            const newSelected = {...prev};
            if (typeof newSelected[id] !== 'undefined') {
                delete newSelected[id];
            } else {
                newSelected[id] = 'referred' // if friends TO even hosts auto accept, otherwise refer;
            }
            console.log('selected friends', newSelected)
            return newSelected;
        });

        setselectedFriendsFullData(prev => {
            console.log("apiResponse", apiResponse?.results)
            const newSelected = {...prev};
            // @ts-ignore
            if(typeof newSelected[id] !== 'undefined') {
                // @ts-ignore
                delete newSelected[id];
            } else {
                //@ts-ignore
                newSelected[id] = apiResponse?.results.find((obj) => obj.author.id === id);
            }
            return newSelected;
        });
    };

    function getOtherId(obj: Friendships) {
        return obj['recipient'] && obj['author'].id === me.id ? obj['recipient'].id : obj['author'].id

    }

    const handleFriendsInvites = async () => {
        setSyncing(true)
        const friends = Object.keys(selectedFriends);
        for (let i = 0; i < friends.length; i++) {
            await ApiClient.post(`/api/invites`, {
                status: selectedFriends[i], // entity.status === 'accepted' ? 'invited' : 'referred', // if friends auto accept, otherwise refer
                event: event_id,
                author: me.id,
                recipient: friends[i]
            });
        }
        const initPagi = parsePagiFiltersFromURL();
        await fetchList(`/api/invites/by-event/${event_id}`, initPagi)
        enqueueSnackbar(`${friends.length} invites sent`)
        setSyncing(false)
        navigate(`/events/${event_id}/invites`)
    }

    if (!apiResponse || (isLoading && !apiResponse?.results)) return <Box p={2}><LinearProgress/></Box>

    if (error) return <Box p={2}><Typography variant={'body2'} color={"error"}>{error}</Typography> </Box>

    return (
        <React.Fragment>
            <Box id={'friendSelector'} className='flex flex-col'>
                <LazyLoadWrapper
                    onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
                    hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
                    threshold={50}
                    isLoading={isLoading}
                >
                    <div className='flex flex-col gap-[14px]'>
                        <HorizontalFriendsToInviteList selectedFriendsFullData={selectedFriendsFullData} handleSelect={handleSelect}/>
                        <Typography variant={'h2'} sx={{paddingX: '16px', paddingBottom: '11px'}}>Recent</Typography>
                        {apiResponse.results.map((obj, i) => {
                            return <FriendInvite onClick={handleSelect}
                                              key={`friendselectorcard-${i}`}
                                              selected={typeof selectedFriends[getOtherId(obj)] !== 'undefined'}
                                              entity={obj as Friendships}
                                              invite={obj?.invite}
                                              event_id={event_id}/>
                            }
                        )}
                    </div>
                </LazyLoadWrapper>
                <Box className='text-center' sx={{
                    position: 'fixed',
                    bottom: 70,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1000,
                    width: 'auto'
                }}>
                    <ButtonPill 
                                sx={{width: '165px', height: '37px'}}
                                variant={'contained'}
                                disabled={syncing}
                                onClick={() => handleFriendsInvites()}>
                        Send
                    </ButtonPill>
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default FriendSelector;
