import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import ApiClient, {HttpResponse} from "../config/ApiClient";
import {ApiListResponse, EntityTypes} from "../object-actions/types/types";

export function buildApiUrl(apiPath: string, offset: number, limit: number) {
    let apiUrl = `${apiPath}`
    if (typeof offset === 'number' && typeof limit === 'number') {
        if (apiUrl.indexOf('?') > -1) {
            apiUrl += `&`
        } else {
            apiUrl += `?`
        }
        apiUrl += `offset=${offset}&limit=${limit}`
    }
    return apiUrl
}

// Function to parse query parameters from the URL
export function parsePagiFiltersFromURL(): PagiFilters {
    const searchParams = new URLSearchParams(window.location.search);
    const hashParams = new URLSearchParams(window.location.hash.slice(1)); // Slice to remove the leading '#'

    const offset = searchParams.get('offset') ? parseInt(searchParams.get('offset')!, 10) : 0;
    const limit = searchParams.get('limit') ? parseInt(searchParams.get('limit')!, 10) : 10;
    const status = searchParams.get('status') || undefined;
    const refresh = hashParams.get('refresh') ? parseInt(hashParams.get('refresh')!, 10) : undefined;

    const filters: PagiFilters = {
        offset,
        limit,
        status,
        refresh,
        append: true
    };

    // Handle any hash parameters that start with "refresh-%"
    hashParams.forEach((value, key) => {
        if (key.startsWith('refresh-')) {
            const [baseKey, ...rest] = key.split('-');
            if (rest.length > 0) {
                filters[baseKey] = parseInt(value, 10) || value;
            }
        }
    });

    return filters;
}


export interface PagiFilters {
    offset: number;
    limit: number;
    status?: string
    refresh?: number; // retries
    append?: boolean;
    count?: number;
    results?: any;

    [key: string]: any; // Allow for additional dynamic keys
}

export interface ProviderResponse<T> {
    apiResponse: T | null;
    error: string | null;
    isLoading: boolean;
}

export function createDataProvider<T>() {
    const DataContext = createContext<{
        dataList: ApiListResponse<T> | null;
        error: string | null;
        isLoading: boolean;
        fetchList: (apiUrl: string, pagi: PagiFilters) => Promise<HttpResponse>;
    } | undefined>(undefined);

    const DataProvider: React.FC<{
        children: ReactNode;
    }> = ({children}) => {
        const [dataList, setDataList] = useState<ApiListResponse<T> | null>(null);
        const [error, setError] = useState<string | null>(null);
        const [isLoading, setIsLoading] = useState<boolean>(false);

        const fetchList = async (baseUrl: string, pagi: PagiFilters): Promise<HttpResponse> => {
            setIsLoading(true);

            let newData: any = dataList ? {...dataList} : {}
            // const nextPage = {offset: newData.offset + newData.limit, limit: newData.limit}
            let apiUrl = buildApiUrl(baseUrl, pagi.offset, pagi.limit)
            const apiData: HttpResponse<any> =  await ApiClient.get<ApiListResponse<EntityTypes>>(apiUrl)

            if (apiData.error) {
                const err = ApiClient.returnErrors(apiData.error)
                setError(err.error || 'unknown error');
            } else {
                if (!dataList || !dataList.results) {
                    newData = apiData.data;
                    Object.assign({}, {...pagi}, newData);
                    console.log(`GP REPLACED ${apiUrl}`, newData)
                } else {
                    newData = JSON.parse(JSON.stringify(dataList))

                    const {results, ...tooverwrite} = apiData.data;
                    Object.assign(newData, tooverwrite);

                    if (newData.results.length > apiData.data.offset) {
                        newData.results.splice(apiData.data.offset, apiData.data.limit, ...results)
                        console.log(`GP SPLICED ${apiUrl}`, newData, apiData.data)
                    } else {
                        newData.results = newData.results.concat(results);
                        console.log(`GP ADDED ${apiUrl}`, newData, apiData.data)
                    }

                }

                setDataList(newData);
                setError(null);
            }
            setIsLoading(false);
            return newData;
        };

        return (
            <DataContext.Provider value={{dataList, error, isLoading, fetchList}}>
                {children}
            </DataContext.Provider>
        );
    };

    const useData = (apiUrl?: string, pagination?: PagiFilters) => {
        const context = useContext(DataContext);

        if (!context) {
            throw new Error(`useData must be used within a GP based on ${apiUrl}`);
        }

        const {dataList, error, isLoading, fetchList} = context;
        const location = useLocation();

        useEffect(() => {
            if (!isLoading && apiUrl && pagination) {
                console.log(`GP useEffect ${apiUrl}`, pagination)
                fetchList(apiUrl, pagination);
            }
        }, [pagination?.offset, pagination?.limit, location.pathname, location.search, location.hash]);

        return {
            apiResponse: dataList,
            error,
            isLoading,
            fetchList
        };
    };


    return {DataProvider, useData};
}
