import React, {useState} from "react";
import {Avatar, Box, LinearProgress, Typography,} from "@mui/material";
import CardHeader, {CardHeaderProps} from "@mui/material/CardHeader";
import {Link} from "react-router-dom";
import {PagiFilters, parsePagiFiltersFromURL} from "../contexts/GenericProvider";
import LazyLoadWrapper from "./LazyLoadWrapper";
import {makeAbsolute} from "../utils";
import {useSongRecommendations} from "../contexts/SongRecommendationsProvider";

interface SongRecommendationsProps {
    event_id?: string | number;
}

const SongRecommendations: React.FC<SongRecommendationsProps> = ({event_id}) => {

    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    let apiUrl = `/api/events/${event_id}/song-recommendations`
    const {
        apiResponse,
        error,
        isLoading
    } = useSongRecommendations(apiUrl, pagination);

    function handlePagination() {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }

    if (!apiResponse || (isLoading && !apiResponse?.results)) return <Box p={2}><LinearProgress/></Box>

    if (error) return <Box p={2}>{error}</Box>

    if (apiResponse.count === 0) return <Box p={2} mt={5} style={{textAlign: 'center'}}><Typography component={Link}
                                                                                                    to={`/events/${event_id}/playlists`}
                                                                                                    variant={'body1'}
                                                                                                    color={'primary'}>Sync
        your playlists to get song recommendations</Typography></Box>

    return (
        <Box p={1} mt={2} id={'SongRecs'}>
            <Typography variant={'h3'} gutterBottom={true}>Recommended Songs</Typography>
            <LazyLoadWrapper
                onLoadMore={() => handlePagination()}
                hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
                threshold={50}
                isLoading={isLoading}
            >
                {apiResponse.results.map((song, idx) => {

                    const headerProps: Partial<CardHeaderProps> = {};

                    if (typeof song.cover === 'string' && song.cover.length > 0) {
                        headerProps.avatar =
                            <Avatar src={makeAbsolute(song.cover)} variant={'rounded'} alt={song.name}/>
                    } else if (typeof song.remote_image === 'string') {
                        headerProps.avatar = <Avatar src={song.remote_image} variant={'rounded'} alt={song.name}/>
                    }

                    headerProps.title = <Typography variant={"body1"}>{song.name}</Typography>
                    headerProps.subheader = <Typography variant={"body2"}>{song.artist}</Typography>
                    headerProps.action = <Typography variant={'overline'} p={1} sx={{
                        borderRadius: 8,
                        backgroundColor: 'primary.main'
                    }}>{song.listener_count}</Typography>

                    return <CardHeader key={`song-${idx}-${song.name}-${song.artist}`} style={{width: '100%'}}
                                       {...headerProps} />
                })}
            </LazyLoadWrapper>
            {isLoading}
        </Box>
    );
}

export default SongRecommendations;
