import React, {createContext, useContext, useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";

interface DebugContextProps {
    debugMode: number;
    setDebugMode: (level: number) => void;
}

const DebugContext = createContext<DebugContextProps>({
    debugMode: 0,
    setDebugMode: (level: number) => null
});

interface DebugProviderProps {
    children: React.ReactNode;
}

const DebugProvider: React.FC<DebugProviderProps> = ({children}) => {
    const [debugMode, setDebugMode] = useState<number>(0);

    const location = useLocation();

    // Extract debug query param from the initial location
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const debugValue = urlParams.get('debug');
        if (debugValue) {
            setDebugMode(parseInt(debugValue, 10) || 0);
        } else {
            setDebugMode(0);
        }
    }, [location]);

    /*** Intercept navigation events to persist the debug query param
     useEffect(() => {
     const originalNavigate = navigate;

     // @ts-ignore
     const enhancedNavigate: typeof navigate = (to, options) => {
     if (typeof to === 'string') {
     const urlParams = new URLSearchParams(location.search);
     if (debugMode) {
     urlParams.set('debug', debugMode.toString());
     }
     const newPath = `${to}?${urlParams.toString()}`;
     originalNavigate(newPath, options);
     } else if (typeof to === 'object' && to.search !== undefined) {
     const urlParams = new URLSearchParams(to.search);
     if (debugMode) {
     urlParams.set('debug', debugMode.toString());
     }
     to.search = `?${urlParams.toString()}`;
     originalNavigate(to, options);
     } else {
     originalNavigate(to, options);
     }
     };

     // Replace the navigate function globally
     (navigate as any) = enhancedNavigate;

     return () => {
     // Restore the original navigate function when the component unmounts
     (navigate as any) = originalNavigate;
     };
     }, [debugMode, location.search, navigate]);
     ***/

    return <DebugContext.Provider
        value={{setDebugMode, debugMode}}>
        {children}
    </DebugContext.Provider>
};

export const useDebug = (): DebugContextProps => {
    const context = useContext(DebugContext);
    if (!context) {
        throw new Error('useDebug must be used within a DebugProvider');
    }
    return context;
};

export default DebugProvider;
