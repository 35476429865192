import { Grid, Typography, Button } from '@mui/material';
import { DateCalendar, StaticDateTimePicker, StaticTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { EntityTypes } from 'src/object-actions/types/types';
import { FieldTypeDefinition } from 'src/object-actions/types/types';
//import ScrollableTimePicker from './ScrollableTimePicker';


// this is a date and time picker specifically for the event form at the moment. 
function DateAndTimePickerForEventForm({field, entity, baseValStartTime, baseValEndTime, handleTimeChange, extraprops}: {field: FieldTypeDefinition, entity: EntityTypes, baseValStartTime: string, baseValEndTime: string, handleTimeChange: (newValue: Dayjs | null, name: string) => void, extraprops: any}) {

    const [showDatePicker, setShowDatePicker] = useState(true);

    // this is a boolean that determines if we are setting the start time or the end time for this component
    const [areWeSettingTheStartTime, setAreWeSettingTheStartTime] = useState(true);

    const [areWeSettingTheStartDate, setAreWeSettingTheStartDate] = useState(true);

  return (
    <Grid container direction={'column'} gap={0} sx={{backgroundColor: 'dateColor.background2', borderRadius: '10px', padding: '16px', paddingBottom: '0px'}}>

        <Typography fontSize={'17px'} fontWeight={'bold'} component={'div'}>Date and Time</Typography>

        <Grid container px={3} mt={2} justifyContent={'space-between'} alignItems={'center'} direction={'row'} wrap={'nowrap'} width={'100%'}>

            <Typography component={'div'} fontWeight={'light'} fontSize={'13px'}>Starts</Typography>

            <Grid container direction={'row'} justifyContent={'flex-end'}>
                <Button
                    onClick={() => {
                        setShowDatePicker(true)
                        setAreWeSettingTheStartDate(true)
                    }}
                    sx={{
                        backgroundColor: 'dateColor.background',
                        color: (showDatePicker && areWeSettingTheStartDate) ? 'dateColor.main' : 'grayedOutText.main',
                        fontSize: '13px',
                        fontWeight: 'normal',
                        borderRadius: '4px',
                        padding: '5px 12px',
                        marginRight: '8px',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: 'dateColor.background',
                        },
                        '&:active': {
                            backgroundColor: 'dateColor.background', 
                        }
                    }}
                >
                    {dayjs(entity['starts' as keyof EntityTypes]).format('MMM D, YYYY')}
                </Button>
                <Button
                    onClick={() => {
                        setShowDatePicker(false)
                        setAreWeSettingTheStartTime(true)
                    }}
                    sx={{
                        backgroundColor: 'dateColor.background',
                        color: (!showDatePicker && areWeSettingTheStartTime) ? 'dateColor.main' : 'grayedOutText.main',
                        fontSize: '13px',
                        fontWeight: 'normal',
                        borderRadius: '4px',
                        padding: '5px 12px',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: 'dateColor.background',
                        },
                        '&:active': {
                            backgroundColor: 'dateColor.background', 
                        }
                    }}
                >
                    {dayjs(entity['starts' as keyof EntityTypes]).format('h:mm A')}
                </Button>
            </Grid>

        </Grid>

        <Grid container px={3} mt={2} justifyContent={'space-between'} alignItems={'center'} direction={'row'} wrap={'nowrap'} width={'100%'}>

            <Typography component={'div'} fontWeight={'light'} fontSize={'13px'}>Ends</Typography>

            <Grid container direction={'row'} justifyContent={'flex-end'}>
                <Button
                    onClick={() => {
                        setShowDatePicker(true)
                        setAreWeSettingTheStartDate(false)
                    }}
                    sx={{
                        backgroundColor: 'dateColor.background',
                        color: (showDatePicker && !areWeSettingTheStartDate) ? 'dateColor.main' : 'grayedOutText.main',
                        fontSize: '13px',
                        fontWeight: 'normal',
                        borderRadius: '4px',
                        padding: '5px 12px',
                        marginRight: '8px',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: 'dateColor.background',
                        },
                        '&:active': {
                            backgroundColor: 'dateColor.background', 
                        }
                    }}
                >
                    {dayjs(entity['ends' as keyof EntityTypes]).format('MMM D, YYYY')}
                </Button>
                <Button
                    onClick={() => {
                    setShowDatePicker(false)
                    setAreWeSettingTheStartTime(false)
                }}
                sx={{
                    backgroundColor: 'dateColor.background',
                    color: (!showDatePicker && !areWeSettingTheStartTime) ? 'dateColor.main' : 'grayedOutText.main',
                    fontSize: '13px',
                    fontWeight: 'normal',
                    borderRadius: '4px',
                    padding: '5px 12px',
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: 'dateColor.background',
                    },
                    '&:active': {
                        backgroundColor: 'dateColor.background', 
                    }
                }}
            >
                {dayjs(entity['ends' as keyof EntityTypes]).format('h:mm A')}
                </Button>
            </Grid>

        </Grid>
        {showDatePicker && (
            <DateCalendar
                sx={{
                    width: '100%',
                    '& .MuiPickersDay-root': {
                        fontSize: '16px',
                        fontWeight: 'normal',
                    },
                    '& .MuiPickersDay-root.Mui-selected': {
                        backgroundColor: 'dateColor.main',
                        '&:hover': {
                            backgroundColor: 'dateColor.main',
                        },
                        '&:focus': {
                            backgroundColor: 'dateColor.main',
                        },
                    },
                    '& .MuiPickersDay-today': {
                        borderColor: 'dateColor.main',
                    },
                    '& .MuiPickersArrowSwitcher-button': {
                        color: 'dateColor.main',
                    },
                }}
                value={typeof baseValStartTime === 'string' ? dayjs.utc(baseValStartTime).local() : baseValStartTime}
                onChange={(newVal) => {
                    if (areWeSettingTheStartDate) {
                        handleTimeChange(newVal, 'starts');
                    } else {
                        handleTimeChange(newVal, 'ends');
                    }
                }}
                {...extraprops}
            />
        )}
        {!showDatePicker && (
            <StaticTimePicker
                //value={areWeSettingTheStartTime ? dayjs.utc(baseValStartTime).local() : dayjs.utc(baseValEndTime).local()}
                value={areWeSettingTheStartTime ? dayjs.utc(entity['starts' as keyof EntityTypes]).local() : dayjs.utc(entity['ends' as keyof EntityTypes]).local()}
                onChange={(newVal) => {
                    handleTimeChange(newVal, areWeSettingTheStartTime ? 'starts' : 'ends');
                }}

                sx={{
                    '& .MuiClock-pin': {
                        backgroundColor: 'dateColor.main',
                    },
                    '& .MuiClockPointer-root': {
                        backgroundColor: 'dateColor.main',
                    },
                    '& .MuiClockPointer-thumb': {
                        backgroundColor: 'dateColor.main',
                        borderColor: 'dateColor.main',
                    },
                    '& .MuiClock-clock': {
                        backgroundColor: 'formBackground.date',
                    },
                    '& .MuiClockNumber-root.Mui-selected': {
                        backgroundColor: 'dateColor.main',
                    },
                    '& .MuiButton-root': {
                        color: 'dateColor.main'
                    },
                }}
                
                ampm
                orientation="portrait"
                views={['hours', 'minutes']}
            />
        )}

        {/* this will be fully finished at a later date as per zach's design */}
        {/* <ScrollableTimePicker entity={entity} handleTimeChange={handleTimeChange} name={areWeSettingTheStartTime ? 'starts' : 'ends'} /> */}
    </Grid>
  )
}
export default DateAndTimePickerForEventForm