import React from 'react';
import {Box, LinearProgress, Typography} from "@mui/material";
import EventsList from "./EventsList";
import {parsePagiFiltersFromURL} from "../contexts/DataProvider";
import {useEventsPending} from "../contexts/EventsPendingProvider";

const EventsPending: React.FC = () => {
    const apiUrl = `/api/events/invited`;
    const pagination = parsePagiFiltersFromURL();
    const invitedEvents = useEventsPending(apiUrl, pagination);

    if (!invitedEvents.apiResponse || invitedEvents.apiResponse?.count === -1) return <Box sx={{padding: 2}} mt={1}><LinearProgress/></Box>
    if (typeof invitedEvents.error === 'string') return <Box sx={{padding: 2}} mt={1}>{invitedEvents.error}</Box>

    return (<Box id={'TopItem'}  style={{minHeight: '100vh'}} mt={7}>
        <Typography mt={3} variant={'h3'} ml={2}>Pending Invitations</Typography>
        {(invitedEvents.apiResponse?.count === 0) ?
        <Typography mt={3} variant={'subtitle2'} ml={2}>No event invites</Typography>
        :
        <EventsList invite_status={'invited'} />
        }
    </Box>)

};

export default EventsPending;
