import React, {useState, useEffect} from 'react';
import {Box, LinearProgress, Typography} from "@mui/material";
import {Friendships} from "../object-actions/types/types";
import {useLocation} from "react-router-dom";
import Friendship from "../components/Friendship";
import {PagiFilters, parsePagiFiltersFromURL} from "../contexts/GenericProvider";
import LazyLoadWrapper from "./LazyLoadWrapper";
import {useFriends} from "../contexts/FriendsProvider";

interface FriendsProps {
    author?: number | string;
}

const Friends: React.FC<FriendsProps> = ({author}) => {
    const location = useLocation();
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());

    let apiUrl = "";
    if (author) {
        apiUrl += `/api/users/${author}/friendships`
    } else {
        apiUrl += `/api/friendships`
    }
    const params = new URLSearchParams(location.search);
    params.set('status', 'accepted')
    apiUrl += `?${params.toString()}`

    const {
        apiResponse,
        error,
        isLoading
    } = useFriends(apiUrl, pagination);

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }

    if (!apiResponse || (isLoading && !apiResponse?.results)) return <Box p={2}><LinearProgress/></Box>

    if (error) return <Box p={2}><Typography variant={'body2'} color={"error"}>{error}</Typography> </Box>

    return (
        <LazyLoadWrapper
            onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
            hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
            threshold={50}
            isLoading={isLoading}
        >
            {apiResponse.results.map((obj, i) => <Friendship entity={obj as Friendships} key={`entitycard-${i}`}/>
            )}
        </LazyLoadWrapper>
    );
};

export default Friends;
