import React, {useEffect, useState} from 'react';
import {Grid, SvgIcon, Typography} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import AppleMusicConnector from "../components/AppleMusicConnector";
import ProviderButton from "../allauth/socialaccount/ProviderButton";
import {WifiPassword} from "@mui/icons-material";
import {ButtonPill} from "../forms/StyledFields";
import {ReactComponent as Spotify} from '../assets/spotify.svg';
import * as allauth from "../allauth/lib/allauth";

const PlaylistsSources: React.FC = () => {

    const params = useParams();
    const event_id = params.id ? parseInt(params.id) : 0;
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState([])

    useEffect(() => {
        allauth.getProviderAccounts().then((resp) => {
            if (resp.status === 200) {
                setAccounts(resp.data)
            }
        })
    }, [])
    const spotifyToken = accounts?.find((a: any) => a.provider.id === 'spotify')

    function onAuthorized(token: string, mkClient: any) {
        navigate(`/events/${event_id}/playlists/applemusic`, {state: {token}})
    }

    return (
        <Grid container direction={'column'} alignItems={'center'} alignContent={'center'} gap={2} p={2} mt={7}>

            <Typography mt={5} variant={'h3'} gutterBottom={true} style={{textAlign: 'center'}}>Select your playlist
                source</Typography>

            <Grid item xs={12}>
                {spotifyToken ?
                    <ButtonPill
                        startIcon={<SvgIcon viewBox="0 0 496 512" component={Spotify} inheritViewBox/>}
                        endIcon={<WifiPassword/>}
                        component={Link}
                        disabled={accounts.length === 0}
                        to={`/events/${event_id}/playlists/spotify`}
                        fullWidth
                        variant={'outlined'}
                        color={'inherit'}


                    >Spotify</ButtonPill>
                    :

                    <ProviderButton
                        connected={false}
                        provider={{name: 'Spotify', id: 'spotify'}}
                    />
                }
            </Grid>
            <Grid item xs={12}>
                <AppleMusicConnector onReady={onAuthorized}/>
            </Grid>
        </Grid>
    );
};

export default PlaylistsSources;
