import React, {useState} from "react";
import {Box, Divider, LinearProgress, Typography,} from "@mui/material";
import {Friendships} from "../object-actions/types/types";
import Friendship from "../components/Friendship";
import {PagiFilters, parsePagiFiltersFromURL} from "../contexts/GenericProvider";
import SongRequestList from "../components/activities/SongRequestList";
import EventCheckinList from "../components/activities/EventCheckinList";
import InviteListByStatus from "../components/activities/InviteListByStatus";
import LazyLoadWrapper from "../components/LazyLoadWrapper";
import { useFriends} from "../contexts/FriendsProvider";

const FriendsRequests: React.FC<ActivityListProps> = ({event_id}) => {

    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const apiUrl = (event_id) ? `/api/friendships/by-event/${event_id}?status=pending,accepted` : `/api/friendships?status=pending,accepted`
    const {
        apiResponse,
        error,
        isLoading
    } = useFriends(apiUrl, pagination);

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }

    if (!apiResponse || (isLoading && !apiResponse?.results)) return <Box p={2}><LinearProgress/></Box>

    if (error) return <Box p={2}>{error}</Box>

    return (
        <LazyLoadWrapper
            onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
            hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
            threshold={50}
            isLoading={isLoading}
        >
            {apiResponse.results.map((req) => {
                return <Friendship
                    key={req.id}
                    entity={req as Friendships}
                />
            })}
            {apiResponse.results.length > 0 && <Divider/>}
        </LazyLoadWrapper>
    );
}


interface ActivityListProps {
    event_id?: string | number;
}

const ActivityList: React.FC<ActivityListProps> = ({event_id}) => {
    return (
        <Box id={'TopItem'}  mt={6} style={{minHeight: '100vh'}}>
            <Box>
                <Typography variant={"h1"} style={{textAlign: 'center'}} gutterBottom={true}>Notifications</Typography>
            </Box>
            <Box>
                <FriendsRequests/>
            </Box>
            <Box>
                <SongRequestList/>
            </Box>
            <Box>
                <EventCheckinList/>
            </Box>
        </Box>
    );
};

export const RecentNotifications: React.FC<ActivityListProps> = ({event_id}) => {
    return (
        <Box >
            <Box id={'awaitingList'}>
                <InviteListByStatus event_id={event_id} status={'requested,referred'}/>
            </Box>
        </Box>
    );
}

export default ActivityList;
