import React from "react";
import {Avatar, Typography, useTheme,} from "@mui/material";
import {Link} from "react-router-dom";
import {EventCheckins, Likes} from "../../object-actions/types/types";
import {useAuth} from "../../allauth/auth";
import CardHeader, {CardHeaderProps} from "@mui/material/CardHeader";
import {getUsername, makeAbsolute, timeAgo} from "../../utils";
import ApiClient, {HttpResponse} from "../../config/ApiClient";
import {useSnackbar} from "notistack";

interface EventCheckinCardProps {
    entity: EventCheckins;
}

const EventCheckinCard: React.FC<EventCheckinCardProps> = ({entity}) => {
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar()

    const me = useAuth()?.data?.user

    const likeEntity = async () => {
        const like: HttpResponse<Likes> = await ApiClient.post(`/api/likes`, {
            event: entity.event.id,
            author: me.id,
            type: "EventCheckins"
        })

        if (like.success) {
            enqueueSnackbar(`Liked`, {variant: 'success', style: {backgroundColor: theme.palette.primary.main}})
        } else {
            enqueueSnackbar(`Error liking`, {variant: 'error'})
        }
    }

    const headerProps: Partial<CardHeaderProps> = {};

    const event = entity['event']
    const username = getUsername(entity['author'])

    if (entity.author && entity.author.img) {
        headerProps.avatar =
            <Avatar component={Link} to={`/users/${entity.author.id}`}
                    src={makeAbsolute(entity.author.img)}
                    alt={username}/>
    } else if (typeof event.img === 'string') {
        headerProps.avatar =
            <Avatar component={Link} to={`/events/${event.id}`}
                    src={makeAbsolute(event.img)}
                    alt={event.str}/>
    } else {
        headerProps.avatar =
            <Avatar component={Link} to={`/events/${event.id}`} src={event['str']} alt={event['str']}/>
    }

    headerProps.title = <Typography variant={"body2"}>
        {username} entered {" "}
        <b>"{entity.event.str}"</b>

        <Typography color={'grey'} ml={1} variant='caption'>{timeAgo(entity.created_at)}</Typography>
    </Typography>
//    headerProps.action = <FavoriteBorder onClick={() => likeEntity()}/>

    return <CardHeader style={{borderBottom: `1px solid ${theme.palette.divider}`, width: '100%'}}
                       key={`entity-${entity.id}-${entity._type}`}
                       {...headerProps} />
}

export default EventCheckinCard;
