import React from 'react';

type SafeAreaProps = {
  children: React.ReactNode;
};

const SafeArea: React.FC<SafeAreaProps> = ({ children }) => {
  const appOS = localStorage.getItem('appOS');

  const isIOS = appOS === 'ios';

  const paddingStyles: React.CSSProperties = isIOS
    ? { paddingTop: '30px', paddingBottom: '20px' } // Adjust the padding values as needed
    : {};

  return <div style={paddingStyles}>{children}</div>;
};

export default SafeArea;
