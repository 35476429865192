import React, {useState} from "react";
import {Avatar, Typography, useTheme} from "@mui/material";
import {Delete, Favorite, FavoriteBorderOutlined} from "@mui/icons-material";
import {Link, useLocation} from "react-router-dom";
import {SongRequests} from "../../object-actions/types/types";
import CardHeader, {CardHeaderProps} from "@mui/material/CardHeader";
import {getUsername, makeAbsolute, timeAgo} from "../../utils";
import ApiClient from "../../config/ApiClient";
import {useSnackbar} from "notistack";
import {useSwipeable} from 'react-swipeable';
import {useFocusedContext} from "../../contexts/FocusedProvider";
import {useAuth} from "../../allauth/auth";
import { useSongRequests} from "../../contexts/SongRequestsProvider";

// Define the expected response type for liking / unliking a song
interface LikeToggleResponse {
    detail?: string; // Adjust the type based on what the API returns
}

interface SongRequestCardProps {
    entity: SongRequests;
}


const SongRequestCard: React.FC<SongRequestCardProps> = ({entity}) => {
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();
    const [hide, setHide] = useState<boolean>(false);
    const me = useAuth()?.data?.user;
    const location = useLocation();
    const {focusedEvent} = useFocusedContext()
    const canManage = focusedEvent && focusedEvent.author.id === me.id || (focusedEvent && focusedEvent.cohosts && focusedEvent?.cohosts.findIndex(rel => rel.id === me.id) > -1);

    const isLoading = useSongRequests().isLoading;

    const [isLiked, setIsLiked] = useState<boolean>(entity.iliked ?? false);
    const [likesCount, setLikesCount] = useState<number>(entity.likes_count ?? 0);
    const [isSwipeRight, setIsSwipeRight] = useState(false);

    const handlers = useSwipeable({
        onSwipedRight: () => setIsSwipeRight(true),
        onSwipedLeft: () => setIsSwipeRight(false),
    });


    const deleteRequest = async () => {
        if (isLoading) return;
        try {
            const response = await ApiClient.delete(`/api/song_requests/${entity.id}`)
            if (response.success) {
                setHide(true); // Toggle the liked state
            } else {
                enqueueSnackbar(`Error removing ${entity.song.str}`, {variant: "error"});
            }
        } catch (error) {
            console.error("Error removing song:", error);
            enqueueSnackbar("Failed to removing the song", {variant: "error"});
        }
    }

    if (hide) return null;

    const likeEntity = async () => {
        if (isLoading) return;

        try {
            const response = await ApiClient.post(`/api/likes/toggle-like`, {
                type: "songrequests",
                item_id: entity.id,
                song: entity.song.id,
                event: entity.event.id,
                playlist: entity.playlist?.id,
            }) as { data: LikeToggleResponse }; // Type assertion for response


            if (response.data.detail) {
                // Update local state based on whether it was liked or unliked
                if (isLiked) {
                    setLikesCount((prevCount) => prevCount - 1);
                } else {
                    setLikesCount((prevCount) => prevCount + 1);
                }
                setIsLiked(!isLiked); // Toggle the liked state

//               const pagination = parsePagiFiltersFromURL();
//               fetchData(apiUrl, (): any => fetchList(apiUrl, pagination), true, false);
            } else {
                enqueueSnackbar(`Error liking ${entity.song.str}`, {variant: "error"});
            }
        } catch (error) {
            console.error("Error liking song:", error);
            enqueueSnackbar("Failed to like the song", {variant: "error"});
        }
    };


    const headerProps: Partial<CardHeaderProps> = {};
    const event = entity.event;
    const username = getUsername(entity.author);


    if (entity.song && entity.song.img) {
        headerProps.avatar = (
            <Avatar src={makeAbsolute(entity.song.img)} alt={entity.song.str} variant={"rounded"}/>
        );
    } else if (typeof event.img === "string") {
        headerProps.avatar = (
            <Avatar component={Link} to={`/events/${event.id}`} src={makeAbsolute(event.img)} variant={"rounded"}
                    alt={username}/>
        );
    } else if (entity.author && entity.author.img) {
        headerProps.avatar = (
            <Avatar src={makeAbsolute(entity.author.img)} alt={username} variant={"rounded"}/>
        );
    } else {
        headerProps.avatar = (
            <Avatar component={Link} to={`/events/${event.id}`} src={event.str} alt={event.str}/>
        );
    }

    headerProps.title = entity.song.str;
    headerProps.subheader = (
        <React.Fragment>
            requested by {username} at {event.str}
            <Typography color={"grey"} ml={1} variant="caption">
                {timeAgo(entity.created_at)}
            </Typography>
        </React.Fragment>
    );


    headerProps.action = (
        <React.Fragment>
            {likesCount > 0 &&
                <Typography variant={"body2"} style={{margin: "8px 5px 0 4px", display: "inline-block"}}>
                    {likesCount}
                </Typography>
            }
            {isLiked ? (
                <Favorite color={isLoading ? "disabled" : "primary"} onClick={likeEntity}/>
            ) : (
                <FavoriteBorderOutlined onClick={likeEntity}/>
            )}
        </React.Fragment>
    );

    const viewEl =
        <CardHeader
            style={{
                transition: 'all 0.4s ease',
                borderBottom: `1px solid ${theme.palette.divider}`,
                width: isSwipeRight ? 'calc(100% - 80px)' : '100%',
                transform: isSwipeRight ? 'translateX(80px)' : 'translateX(0)', // Move the card to the left when swiped
            }}
            key={`entity-${entity.id}-${entity._type}`}
            {...headerProps}
        />

    if (location.pathname.indexOf('/activity') === 0 || !canManage) {
        return viewEl;
    }

    return (<div style={{position: 'relative'}} {...handlers} >
        <div
            style={{
                position: 'absolute',
                textAlign: 'center',
                backgroundColor: "#ff2a2a",
                width: 80,
                alignContent: 'center',
                top: '50%',
                height: '100%',
                left: isSwipeRight ? 0 : -90,
                transition: 'all 0.6s ease', // Smooth transition for the button
                transform: 'translateY(-50%)',
                zIndex: 1,
            }}
            onClick={() => deleteRequest()}>
            <Delete color={'inherit'}/>
        </div>
        {viewEl}
    </div>);
};


export default SongRequestCard;
