import React, {useMemo, useState} from 'react';
import Cropper from 'react-easy-crop';
import {Button, Grid} from '@mui/material';
import {blobToFile, getCroppedImg, PixelCrop} from "../../utils";
import {useLocation} from "react-router-dom";

interface CropperComponentProps {
    imageSrc: string;
    onDone: (url: string, file: Blob) => void;
    onChangeImage: () => void;
    onCancel: () => void;
}

const CropperComponent: React.FC<CropperComponentProps> = ({imageSrc, onDone, onChangeImage, onCancel}) => {
    const [crop, setCrop] = useState({x: 0, y: 0})
    const [rotation, setRotation] = useState<number>(0)
    const [zoom, setZoom] = useState<number>(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<PixelCrop>()
    const initialCroppedAreaPercentages = useMemo(() => {
        return {x: 0, y: 0, width: 50, height: 50};
    }, []);
    const location  = useLocation();

    const onCropComplete = (croppedArea: PixelCrop, croppedAreaPixels: PixelCrop) => {
        console.log("Crop Complete", croppedArea, croppedAreaPixels)
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const showCroppedImage = async () => {
        if (!croppedAreaPixels) {
            console.warn("No crop area defined")
            return false;
        }
        try {
            const croppedImageUrl = await getCroppedImg(imageSrc, croppedAreaPixels, rotation)
            console.log(`done cropping  ${croppedImageUrl}`)
            if (croppedImageUrl) {
                const response = await fetch(croppedImageUrl);
                const blob = await response.blob();
                const file = blobToFile(blob, imageSrc);
                onDone(croppedImageUrl, file);
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Grid container flexDirection={'column'} direction={'column'} justifyContent={'space-between'} wrap={'nowrap'}
              style={{position: 'relative', height: '90vh'}}>
            <Grid item xs={12} justifyContent={'space-between'} container>
                <Grid item>
                    <Button
                        onClick={onCancel}
                        variant="text"
                        color="primary"
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} flexGrow={1}>
                <Grid container direction={'column'} justifyContent={'space-between'} wrap={'nowrap'}
                      style={{position: 'relative', width: '100%', height: '80vh'}}>
                    <Grid item xs={12} style={{position: 'relative', width: '100%', height: '40vh'}}>
                        <Cropper
                            image={imageSrc}
                            rotation={0}
                            crop={crop}
                            aspect={1}
                            showGrid={false}
                            objectFit={'contain'}
                            cropShape={location.pathname.indexOf('/users/') > -1 ? 'round' : 'rect'}
                            zoom={zoom}
                            initialCroppedAreaPercentages={initialCroppedAreaPercentages}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Button
                    onClick={showCroppedImage}
                    variant="text"
                    color="primary"
                    fullWidth={true}
                >
                    DONE
                </Button>
            </Grid>
        </Grid>
    );
};


export default CropperComponent;
