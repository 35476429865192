import React, {useEffect} from 'react';
import {Box, Grid, LinearProgress, SvgIcon, Typography} from "@mui/material";
import FullscreenBG from "../components/FullscreenBg";
import {ButtonPill, GradientButton, SecondaryBottomMenu} from "../forms/StyledFields";
import {useNavigate} from "react-router-dom";
import EventsList from "./EventsList";
import {Add} from "@mui/icons-material";
import Badge from "@mui/material/Badge";
import {ReactComponent as PaperPlane} from '../assets/paper-plane.svg';
import {getSafeAreaPadding} from "../utils";
import {parsePagiFiltersFromURL, useDataContext} from "../contexts/DataProvider";
import {fetchList} from "../hooks/useQuery";

const EventsDashboard: React.FC = () => {
    const navigate = useNavigate()
    const {fetchData, dataStore} = useDataContext()

    useEffect(() => {
        async function start() {
            const pagination = parsePagiFiltersFromURL();
            // await fetchData(`/api/events/mine`, (): any => fetchList(`/api/events/mine`, pagination), false, pagination)
            // await fetchData(`/api/events/invited`, (): any => fetchList(`/api/events/invited`, pagination), false, pagination)
            await fetchData(`/api/events/accepted`, (): any => fetchList(`/api/events/accepted`, pagination), false, pagination)
            // await fetchData(`/api/events/pending`, (): any => fetchList(`/api/events/pending`, pagination), false, pagination)

            console.log(dataStore[`/api/events/accepted`])
        }
        start();
    }, []);

    const myEvents = dataStore[`/api/events/mine`]
    const invitedEvents = dataStore[`/api/events/invited`]
    const acceptedEvents = dataStore[`/api/events/accepted`]
    const pendingEvents = dataStore[`/api/events/pending`]
    


    // This is to fix duplicate event issue. Originally, an event that a user is attending AND a co-host of will render twice:
    // once under your events (co-host), and once under scheduled/accepted events. 
    // The code below filters out the events that already have 'mine' status from the 'accepted' events.
    // Essentially, an event that you're a co-host of will only render once under "your events".
    const myEventsIDs = new Set(myEvents?.results?.map((event: Record<string, any>) => event.id) || [])
    const filteredAcceptedEvents = {
        ...acceptedEvents,
        results: acceptedEvents?.results?.filter((event: Record<string, any>) => !myEventsIDs.has(event.id)) || []
    }
    dataStore[`/api/events/accepted`] = filteredAcceptedEvents


    const paddingTop = getSafeAreaPadding('paddingTop') + 5;

    return <React.Fragment>
        <FullscreenBG imageurl={'/events-background.png'}/>
        <Box id={'TopItem'}  mt={7} style={{position: 'relative', zIndex: 1, minHeight: '100vh'}}>
            <Typography variant={"h1"} style={{textAlign: 'center'}} gutterBottom={true}>Events</Typography>
            {invitedEvents?.count > 0 ?
                <Badge
                    color={'primary'}
                    onClick={() => navigate('/events/invited')}
                    overlap="circular"
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    sx={{position: 'fixed', right: 20, top: paddingTop, zIndex: 9}}
                    badgeContent={invitedEvents?.count}>

                    <SvgIcon component={PaperPlane} inheritViewBox/>
                </Badge>
                :
                <Badge
                    color={'primary'}
                    overlap="circular"
                    onClick={() => navigate('/events/invited')}
                    sx={{position: 'fixed', right: 20, top: paddingTop}}
                    badgeContent={0}>
                    <SvgIcon component={PaperPlane} inheritViewBox/>
                </Badge>
            }

            <Box>
                {myEvents?.count > 0 &&
                    <Typography mt={3} variant={'h3'} ml={2}>Your Events</Typography>
                }
                <EventsList invite_status={'mine'}/>
            </Box>

            <Box>
                {acceptedEvents?.count > 0 &&
                    <Typography mt={3} variant={'h3'} ml={2}>Scheduled</Typography>

                }
                <EventsList invite_status={'accepted'}/>
            </Box>

            <Box>
                {pendingEvents?.count > 0 &&
                    <Typography mt={3} variant={'h3'} ml={2}>Pending</Typography>

                }
                <EventsList invite_status={'pending'}/>
            </Box>

            {(!myEvents?.count && !acceptedEvents?.count) ?
                <Grid item style={{textAlign: 'center'}}>
                    <Typography variant={"body2"} gutterBottom={true}>You don't have any events</Typography>
                    <GradientButton
                        endIcon={<Add/>}
                        onClick={() => navigate(`/forms/events/0/add`)}>
                        Create An Event
                    </GradientButton>
                </Grid>
                :
                (myEvents?.count === -1 || acceptedEvents?.count === -1 || pendingEvents?.count === -1 || invitedEvents?.count === -1) ?
                    <LinearProgress/>
                    :
                    <SecondaryBottomMenu style={{textAlign: 'center'}}>
                        <ButtonPill
                            onClick={() => navigate(`/forms/events/0/add`)}
                            variant={'contained'} style={{margin: 'auto'}}>Create an Event
                        </ButtonPill>
                    </SecondaryBottomMenu>
            }

        </Box>
    </React.Fragment>
}

export default EventsDashboard;
