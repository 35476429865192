import React, {ChangeEvent, useState} from 'react';
import {Box, Grid, MenuItem, Typography} from "@mui/material";
import {Friendships} from "../object-actions/types/types";
import CardHeader, {CardHeaderProps} from '@mui/material/CardHeader';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import {useAuth} from "../allauth/auth";
import {FriendButton, SelectPill} from "../forms/StyledFields";
import ApiClient from "../config/ApiClient";
import {getFullName, getUsername, makeAbsolute, timeAgo} from "../utils";
import {useDebug} from "../contexts/Debug";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface FriendshipProps {
    entity: Friendships;
}

const Friendship: React.FC<FriendshipProps> = ({entity}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const me = useAuth()?.data?.user
    const {debugMode} = useDebug()
    const [status, setStatus] = useState<string>(entity.status);

    const handleStatusChange = async (e: ChangeEvent<HTMLInputElement> | any) => {
        e.preventDefault();
        e.stopPropagation();
        const {value} = e.target;

        const response = await ApiClient.patch(`/api/friendships/${entity.id}`, {status: value});
        if (response.success && response.data) {
            console.log(response.data);
            setStatus(value)
            // or fetch new friendship data if you can get the current key
        }
        if (response.error) {
            alert(response.error)
        }
    };

    const headerProps: Partial<CardHeaderProps> = {};

    const userViewing: number = location.pathname.indexOf("/users") === 0 && params.uid ? parseInt(params.uid) : me.id
    const other = entity['author'].id === userViewing ? entity['recipient'] : entity['author']

    if (typeof other.img === 'string') {
        headerProps.avatar =
            <Avatar component={Link} to={`/users/${other.id}`} src={makeAbsolute(other.img)}
                    alt={other['str']}/>
    } else {
        headerProps.avatar = <Avatar component={Link} to={`/users/${other.id}`} src={other['str']} alt={other['str']}/>
    }

    if (status === 'accepted') {
        headerProps.title = <Typography variant={'body2'}>{getFullName(other)}</Typography>
    } else {
        const suffix = me.id === entity['author'].id ? ' has your friend request' : ' sent a friend request';
        headerProps.title = <Typography variant={'body2'}><em>{getFullName(other)}</em> {suffix}</Typography>
    }

    headerProps.subheader = <React.Fragment>
                    {getUsername(other)}
                    <Typography color={'grey'} ml={1} variant='caption'>{timeAgo(entity.modified_at)}</Typography>
    </React.Fragment>
    headerProps.onClick = () => navigate(`/users/${other.id}`)

    if (me.id === other.id) {
        headerProps.action = null;
    } else if (debugMode) {
        headerProps.action = <Box mt={2}><SelectPill
            select
            size={'small'} variant={'filled'}
            color={'secondary'}
            label=''
            value={status}
            onChange={handleStatusChange}
        >
            <MenuItem disabled={true} value={'pending'}>Pending</MenuItem>
            <MenuItem value={'accepted'}>{status === 'accepted' ? 'Friend' : 'Accept'}</MenuItem>
            <MenuItem value={'declined'}>{status === 'declined' ? 'Declined' : 'Decline'}</MenuItem>
            <MenuItem value={'withdrawn'}>{status === 'withdrawn' ? 'Withdrawn' : 'Withdraw'}</MenuItem>
        </SelectPill></Box>
    } else if (status === 'accepted') {
        headerProps.action = <FriendButton
            size={'small'}
            variant={'contained'}
            color={'inherit'}
            value={'withdrawn'}
            sx={{marginLeft: 1}}
            //startIcon={status === "accepted" ? <CheckCircleIcon/> : undefined}
            onClick={handleStatusChange}
        >
            Friends
        </FriendButton>
    } else if (me.id === entity.recipient.id) {
        headerProps.action = <Grid container spacing={1}>
            <Grid item>
                <FriendButton
                    size={'small'}
                    variant={'outlined'}
                    color={'primary'}
                    value={'accepted'}
                    //startIcon={status === "accepted" ? <CheckCircleIcon/> : undefined}
                    onClick={handleStatusChange}
                >
                    Confirm
                </FriendButton>
            </Grid>
            <Grid item>
                <FriendButton
                    size={'small'}
                    variant={'contained'}
                    color={'inherit'}
                    value={'declined'}
                    //startIcon={status === "declined" ? <CheckCircleIcon/> : undefined}
                    onClick={handleStatusChange}
                >
                    Reject
                </FriendButton>
            </Grid>

            <Grid item>
                <FriendButton
                    size={'small'}
                    variant={'contained'}
                    color={'inherit'}
                    value={'blocked'}
                    startIcon={status === "blocked" ? <CheckCircleIcon/> : undefined}
                    onClick={handleStatusChange}
                >
                    Block
                </FriendButton>
            </Grid>
        </Grid>
    } else if (me.id === entity.author.id) {
        headerProps.action = <FriendButton
            size={'small'}
            variant={'contained'}
            color={'inherit'}
            value={'withdrawn'}
            sx={{marginLeft: 1}}
            //startIcon={status === "withdrawn" ? <CheckCircleIcon/> : undefined}
            onClick={handleStatusChange}
        >
            Withdraw
        </FriendButton>
    }


    return <CardHeader  {...headerProps} />
}

export default Friendship;
