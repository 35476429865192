import React from 'react';
import {RelEntity} from "../object-actions/types/types";
import AutocompleteField from "../object-actions/forms/AutocompleteField";
import {useNavigate} from "react-router-dom";
import {AppBar, Toolbar} from "@mui/material";
import {getSafeAreaPadding} from "../utils";

interface FriendRequestProps {
    onRequested?: (selected: RelEntity) => void;
}

const FriendRequester: React.FC<FriendRequestProps> = ({onRequested}) => {
    const navigate = useNavigate();

    const handleSelect = async (value: RelEntity | null, field_name: string) => {
        if (!value) return false;
        if (onRequested) {
            onRequested(value);
        } else {
            navigate(`/users/${value.id}`);
        }
    };

    return (
            <AutocompleteField
                            type={"friendships"}
                            search_fields={[
                                "full_name",
                                "username"
                            ]}
                            image_field={'profile_picture'}
                            onSelect={handleSelect}
                            field_name={'recipient'}
                            field_label={""}
                            selected={null}
            />
    );
};

export default FriendRequester;
