import React from 'react';
import {Typography} from "@mui/material";
import {Invites} from "../object-actions/types/types";
import Avatar from "@mui/material/Avatar";
import CardHeader, {CardHeaderProps} from "@mui/material/CardHeader";
import {useNavigate} from "react-router-dom";
import {getFullName, getUsername, makeAbsolute} from "../utils";
import {FriendshipRelButton} from "../forms/FriendshipButton";
import {Circle} from "@mui/icons-material";

interface InviteProps {
    entity: Invites;
}

const InviteToMe: React.FC<InviteProps> = ({entity}) => {
    const navigate = useNavigate();

    const headerProps: Partial<CardHeaderProps> = {};

    const other = entity['author']
    const myRel = entity['recipient']

    headerProps.avatar =
        <Avatar src={typeof myRel.img === 'string' ? makeAbsolute(myRel.img) : myRel.str}
                alt={myRel['str']}
                onClick={() => navigate(`/users/${myRel.id}`)}/>

    headerProps.title = <Typography variant={'subtitle1'}>{getFullName(myRel)} {entity?.checkin &&
                    <Circle color={'success'} sx={{fontSize: 10, verticalAlign: 'baseline'}}/>}</Typography>

    if (entity.status === 'accepted') {
        headerProps.subheader = <Typography variant={'body2'}>invited by {getUsername(other)}</Typography>
    } else if (entity.status === 'invited') {
        // activity page format
        headerProps.subheader = <Typography variant={'body2'}><em>{getFullName(other)}</em> sent you an event invite</Typography>
    } else {
         headerProps.subheader = <Typography variant={'body2'}><em>{getFullName(other)}</em> {entity.status} you</Typography>
    }

    if (entity.friendship) {
        headerProps.action = <FriendshipRelButton friendship={entity.friendship} recipient={other} />
    } else {
        headerProps.action = <FriendshipRelButton recipient={other} />
    }


    return <CardHeader {...headerProps} />
}

export default InviteToMe;
