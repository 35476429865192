import React, {useEffect, useState} from 'react';
import {CardHeader, CircularProgress} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useFocusedContext} from "../../contexts/FocusedProvider";
import {useParams} from "react-router-dom";
import ApiClient, {HttpResponse} from "../../config/ApiClient";

const InviteLinkButton: React.FC = () => {
    const {enqueueSnackbar} = useSnackbar();
    const {focusedEvent, fetchEvent} = useFocusedContext()
    const [loading, setLoading] = useState<boolean>(false);
    const params = useParams();

    useEffect(() => {
        if (!focusedEvent) {
            fetchEvent(params.id ?? '')
        }
    }, [])

    if (!focusedEvent) return null;

    const handleShare = async () => {
        const {name} = focusedEvent;
        setLoading(true);

        let apiUrl = `${process.env.REACT_APP_API_HOST}/api/invite_links`
        const response: HttpResponse<any> = await ApiClient.post(apiUrl, {event:focusedEvent.id});
        setLoading(false);
        if (response.error) {
            return enqueueSnackbar(response.error)
        } else {
            console.log(response.data)
        }

        const url = `${process.env.NODE_ENV === 'production' ? 'https://djmote.com' : process.env.REACT_APP_HOST}?i=${encodeURIComponent(response.data.url_alias)}`
        console.log("SHARING: " +url)

        if (navigator.share) {
            try {
                await navigator.share({
                    title: name,
                    url,
                });
            } catch (error) {
                console.error('Error sharing:', error);
                enqueueSnackbar('Failed to share the link', {variant: 'error'});
            }
        } else if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('ShareEvent', {url, name});
            enqueueSnackbar('Link sent to Flutter WebView for sharing', {variant: 'info'});
        } else if (navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(url);
                enqueueSnackbar('Link copied to clipboard', {variant: 'success'});
            } catch (error) {
                console.error('Failed to copy text: ', error);
                enqueueSnackbar('Failed to copy text', {variant: 'error'});
            }
        } else {

            enqueueSnackbar('Sharing is not supported on this platform', {variant: 'warning'});
        }
    };

    return (
        // <CardHeader title={'Invite a Friend'} subheader={'Send an invite link to a friend'} sx={{padding:0, margin:0}}
        //             avatar={loading ? <CircularProgress /> : <img src={'/assets/invite-link.png'} color="inherit" aria-label="share link" className='size-[50px]'/>}
        //             onClick={handleShare}
        // />

        <div className='flex flex-row items-center w-full rounded-[10px] gap-[8px]'>
            <div className='flex flex-row justify-center items-center gap-[10px]'>
                <img src={'/assets/invite-link.png'} color="inherit" aria-label="share link" className='size-[50px]'/>
            </div>
            <div className='flex flex-col justify-center'>
                <div className='text-[12px] font-bold'>Invite a Friend</div>
                <div className='text-[10px] font-bold text-grayedOutText'>Send an invite link to a friend</div>
            </div>
        </div>

    );
};

export default InviteLinkButton;
