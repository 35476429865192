import React from 'react';
import {Outlet} from 'react-router-dom';
import {Box, Grid} from "@mui/material";
import BottomMenu from "../components/BottomMenu";
import {useAuth} from "../allauth/auth";
import {FocusedProvider} from "../contexts/FocusedProvider";
import DebugProvider from "../contexts/Debug";
import BackButton from "./BackButton";
import EventMenu from "../components/EventMenu";
import {getSafeAreaPadding} from "../utils";
import {SongRequestsProvider} from "../contexts/SongRequestsProvider";
import {SongRecommendationsProvider} from "../contexts/SongRecommendationsProvider";
import {FriendsProvider} from "../contexts/FriendsProvider";
import {EventsPendingProvider} from "../contexts/EventsPendingProvider";
import {LikesProvider} from "../contexts/LikesProvider";
import {InvitesProvider} from "../contexts/InvitesProvider";
import {SocialPlaylistsProvider} from "../contexts/SocialPlaylistsProvider";
import DataProvider from "../contexts/DataProvider";
import {EventCheckinsProvider} from "../contexts/EventCheckinsProvider";

const Layout: React.FC = () => {
    const me = useAuth()?.data?.user;

    const padding = getSafeAreaPadding('paddingTop') + getSafeAreaPadding('paddingBottom')

    return (
        <DebugProvider>
            <Grid container direction={'column'} justifyContent={'flex-start'}
                  sx={{minHeight: window.innerHeight - padding}}>

                <Grid container justifyContent={'space-around'} flexWrap={'nowrap'}>
                    <Grid item flexGrow={1}>
                        <Box id={"AppContent"} style={{
                            width: '100%',
                            margin: `0 auto 60px auto`,
                            maxWidth: 1024
                        }}>
                            <FocusedProvider>
                                {me ?
                                    <DataProvider>
                                        <EventCheckinsProvider>
                                            <FriendsProvider>
                                                <EventsPendingProvider>
                                                    <LikesProvider>
                                                        <InvitesProvider>
                                                            <SocialPlaylistsProvider>
                                                                <SongRecommendationsProvider>
                                                                    <SongRequestsProvider>
                                                                        <BackButton/>
                                                                        <Outlet/>
                                                                        <BottomMenu/>
                                                                        <EventMenu/>
                                                                    </SongRequestsProvider>
                                                                </SongRecommendationsProvider>
                                                            </SocialPlaylistsProvider>
                                                        </InvitesProvider>
                                                    </LikesProvider>
                                                </EventsPendingProvider>
                                            </FriendsProvider>
                                        </EventCheckinsProvider>
                                    </DataProvider>
                                    :
                                    <React.Fragment>
                                        <Outlet/>
                                        <BackButton/>
                                    </React.Fragment>
                                }
                            </FocusedProvider>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </DebugProvider>
    );
};

export default Layout;
