import React, {useState} from "react";
import {Box, LinearProgress, Typography} from "@mui/material";
import {Invites} from "../../object-actions/types/types";
import Invite from "../../components/Invite";
import {useParams} from "react-router-dom";
import {PagiFilters, parsePagiFiltersFromURL} from "../../contexts/GenericProvider";
import { useInvites } from "../../contexts/InvitesProvider";
import LazyLoadWrapper from "../LazyLoadWrapper";

interface InviteListByStatusProps {
    event_id?: number | string | undefined;
    status?: string | undefined;
}

export const StatusLabels: { [key: string]: string } = {
    "invited": "Hasn't responded yet", // user directly invited by host / cohost
    "seen": "Viewed Invite link", // the invite link was viewed a default invite was created for this user.
    "referred": "Referred", // non host sends invite using app
    "requested": "Accepted Invite Link", // "ill be there" was clicked from a seen or referred invite
    "accepted": "Accepted",
    "declined": "Rejected",
    "withdrawn": "Withdrawn"
}

const InviteListByStatus: React.FC<InviteListByStatusProps> = ({
                                                                   event_id,
                                                                   status = 'accepted'
                                                               }) => {
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const params = useParams();
    const final_id = event_id ? parseInt(event_id.toString()) : (params.id ? parseInt(params.id) : 0);

    // TODO if host add invited
    const apiUrl = (final_id > 0 && window.location.pathname.indexOf('/events/') === 0) ? `/api/invites/by-event/${final_id}?status=${status}` : `/api/invites?status=${status}`

    const {
        apiResponse,
        error,
        isLoading
    } = useInvites(apiUrl, pagination);

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }

    if (!apiResponse || (isLoading && !apiResponse?.results)) return <Box><LinearProgress/></Box>

    if (error) return <Typography variant={'body2'} color={"error"}>{error}</Typography>

    return (
        <LazyLoadWrapper
            onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
            hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
            threshold={50}
            isLoading={isLoading}
        >
            {apiResponse.results.map((cur: Invites) => <Invite key={`awaitingapproval-${cur.id}`} entity={cur as Invites}/>)}

        </LazyLoadWrapper>
    );
};

export default InviteListByStatus;
