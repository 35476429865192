import React from 'react';
import {Box, Typography} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {ButtonPill} from "../forms/StyledFields";
import FullscreenBG, {SoloCenteredBox} from "../components/FullscreenBg";

export const EventPublicView: React.FC = () => {
    const location = useLocation()
    return <Box style={{position: 'relative'}}><SoloCenteredBox>
        <FullscreenBG imageurl={'/events-background.png'} />
        <div style={{textAlign: 'center', zIndex:1}}>
            <Typography color={'#fff'} gutterBottom={true} variant={'h2'}>Create an account <br/>or sign in to view
                event</Typography>
            <ButtonPill variant={'contained'} component={Link}
                        to={`/account/sms?next=${encodeURIComponent(location.pathname + location.search)}`}>Sign up / Sign
                in</ButtonPill>
        </div>
    </SoloCenteredBox></Box>

};

export default EventPublicView;
