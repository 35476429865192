import React, {ReactElement, useState} from "react";
import {Box, Grid, LinearProgress, Typography} from "@mui/material";
import {ApiListResponse, Invites} from "../object-actions/types/types";
import Invite from "../components/Invite";
import {useNavigate, useParams} from "react-router-dom";
import {PagiFilters, parsePagiFiltersFromURL, useData} from "../contexts/DataProvider";
import {fetchList} from "../hooks/useQuery";
import {SoloCenteredBox} from "../components/FullscreenBg";
import LazyLoadWrapper from "../components/LazyLoadWrapper";
import IconButton from "@mui/material/IconButton";
import {PersonAdd} from "@mui/icons-material";
import {useFocusedContext} from "../contexts/FocusedProvider";
import {useInvites} from "../contexts/InvitesProvider";

type GroupedInvites = {
    [status: string]: ReactElement[];
};

const StatusLabels: { [key: string]: string } = {
    "invited": "Hasn't responded yet",
    "requested": "Awaiting Link Approval",
    "referred": "Awaiting Referred Approval",
    "accepted": "Accepted",
    "declined": "Can't make it",
    "seen": "Viewed Invite link",
    "withdrawn": "Withdrawn"
}

const InviteList: React.FC = () => {
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const navigate = useNavigate();
    const {focusedEvent} = useFocusedContext()

    const apiUrl = focusedEvent && focusedEvent.id ? `/api/invites/by-event/${focusedEvent.id}` : `/api/invites`;
    const {
        apiResponse,
        error,
        isLoading
    } = useInvites(apiUrl, pagination);

    if (!focusedEvent) return null;

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }


    if (!apiResponse || (isLoading && !apiResponse?.results)) return <Box p={2}><LinearProgress/></Box>

    if (error) return <SoloCenteredBox><Typography variant={'body2'} color={"error"}>{error}</Typography>
    </SoloCenteredBox>

    //  const myInvite = apiResponse.results.find(obj => obj.recipient.id === me.id)

    const groups = apiResponse.results.reduce((acc: GroupedInvites, cur: Invites) => {
//        if (myInvite && myInvite.id == cur.id) return acc;
        if (typeof acc[cur.status] === 'undefined') acc[cur.status] = [];
        acc[cur.status].push(<Invite key={`entitycard-${cur.id}`} entity={cur as Invites}/>);
        return acc;
    }, {} as GroupedInvites);


    return (
        <Box id={'TopItem'} mt={8} style={{minHeight: '100vh'}}>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item></Grid>
                <Grid item>
                    <Typography variant={'h1'} style={{textAlign: 'center'}}>Invite List</Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={() => navigate(`/events/${focusedEvent.id}/invite`)}>
                        <PersonAdd/>
                    </IconButton>
                </Grid>
            </Grid>

            {
                isLoading && <LinearProgress/>
            }

            <LazyLoadWrapper
                onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
                hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
                threshold={50}
                isLoading={isLoading}
            >
                {Object.entries(groups).map(([key, value]) => <Box mb={1} p={1} key={`invitelist-${key}`}>
                        {typeof StatusLabels[key] === 'string' &&
                            <Typography variant={'h3'}>{StatusLabels[key]}</Typography>}
                        {value}
                    </Box>
                )}
            </LazyLoadWrapper>
        </Box>
    )
        ;
};

export default InviteList;
