import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import {Users} from "../object-actions/types/types";
import Typography from '@mui/material/Typography';
import Avatar from "@mui/material/Avatar";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import {EditOutlined} from "@mui/icons-material";
import {useAuth} from "../allauth/auth";
import {StyledBadge} from "../forms/StyledFields";
import FriendshipButton from "../forms/FriendshipButton";
import {getFullName, getUsername, makeSocialLink} from "../utils";
import {styled, SvgIcon} from "@mui/material";
import {ReactComponent as Spotify} from '../assets/spotify.svg';
import {ReactComponent as AppleMusic} from '../assets/applemusic.svg';
import {ReactComponent as Instagram} from '../assets/instagramnew.svg';
import {ReactComponent as EditIcon} from '../assets/editicon.svg';

interface UserCardProps {
    entity: Users;
}

const BlurredBackground = styled(Grid)(({ theme }) => ({
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -150,
      left: 0,
      right: 0,
      bottom: 0,
      filter: 'blur(100px)',
      opacity: 0.45,
      zIndex: -1,
      transform: 'scale(0.9)', // Prevents blur edges from showing
    }
  }));

const UserCard: React.FC<UserCardProps> = ({entity}) => {
    const me = useAuth()
    const isMe = entity.id === me?.data?.user.id    
    
    const [gradientColors, setGradientColors] = useState<string[]>(['#ffffff', '#ffffff']);

    // Extract gradient colors from the profile picture to use in the blurred background. 
    // AI wrote this for me, no clue how it works, but it works.
    // In the future this should be refactored as its own utility function.
    useEffect(() => {
        const extractColors = async () => {
            if (!entity.profile_picture) return;

            try {
                // Create a new image object
                const img = new Image();
                img.crossOrigin = "Anonymous";  // Handle CORS issues
                
                img.onload = () => {
                    // Create canvas to analyze image
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    
                    if (ctx) {
                        ctx.drawImage(img, 0, 0);
                        
                        // Sample colors from different parts of the image
                        const topColor = ctx.getImageData(0, 0, 1, 1).data;
                        const bottomColor = ctx.getImageData(0, img.height - 1, 1, 1).data;
                        
                        setGradientColors([
                            `rgb(${topColor[0]}, ${topColor[1]}, ${topColor[2]})`,
                            `rgb(${bottomColor[0]}, ${bottomColor[1]}, ${bottomColor[2]})`
                        ]);
                    }
                };

                img.src = entity.profile_picture;
            } catch (error) {
                console.error('Error extracting colors:', error);
            }
        };

        extractColors();
    }, [entity.profile_picture]);

    return <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>

        <BlurredBackground 
            container 
            direction={'column'} 
            justifyContent={'center'} 
            alignItems={'center'} 
            alignContent={'center'}
            sx={{
                '&::before': {
                    background: entity.profile_picture
                        ? `linear-gradient(45deg, ${gradientColors[0]}, ${gradientColors[1]})`
                        : 'transparent',
                }
            }}
        >

            <Typography variant={'h5'} fontWeight={'bold'}> 
                {getFullName(entity)}
                {!entity.profile_picture && isMe && 
                    <IconButton component={Link} to={`/forms/users/${entity.id}/edit`}>
                        <EditOutlined color="secondary" fontSize={'small'}/>
                    </IconButton>
                }
            </Typography>

            {!entity.profile_picture ?
                null :
                !isMe ?
                    <Grid item>
                        <Avatar sx={{width: 115, height: 115}}
                                alt={entity.username}
                                src={entity.profile_picture}/>
                    </Grid>
                    :
                    <Grid item>
                        <IconButton component={Link} to={`/forms/users/${entity.id}/edit`}>
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                // badgeContent={<EditOutlined color="secondary" fontSize={'small'}/>}
                                badgeContent={<EditIcon style={{width: 22, height: 22, transform: 'translate(-2px, -2px)'}} />}
                            >
                                <Avatar sx={{width: 115, height: 115}}
                                        alt={entity.username}
                                        src={entity.profile_picture}/>
                            </StyledBadge>
                        </IconButton>
                    </Grid>
            }

            <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} mt={0} gap={.8} style={{textAlign: 'center'}}>
                <Typography variant={'body1'} color={'grayedOutText.main'}>{getUsername(entity)}</Typography>
                {entity.bio && <Typography variant={'body1'} fontWeight={'normal'} color={'grayedOutText.secondary'}>{entity.bio}</Typography>}


                {/* {entity.link_ig &&
                    <Typography variant={'body1'}>
                        <SvgIcon component={Instagram} inheritViewBox/> 
                        <a href={entity.link_ig} target="_blank">
                            {makeSocialLink(entity.link_ig)}
                        </a>
                    </Typography>} */}

                
                {entity.link_ig &&
                    <Typography variant={'body1'} color={'grayedOutText.tertiary'} sx={{fontWeight: 'normal', textDecoration: 'none', display: 'flex', alignItems: 'center', gap: .4}}>
                        <a href={entity.link_ig} target="_blank" style={{textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '4px'}}>
                            <SvgIcon component={Instagram} inheritViewBox htmlColor="none" style={{width: 16, height: 16, color: 'inherit', background: 'transparent', fill: 'none'}}/> 
                            Instagram
                        </a>
                    </Typography>
                }


            </Grid>
        </BlurredBackground>
        
        {entity.link_spotify &&
          <Typography variant={'body1'}><SvgIcon viewBox="0 0 496 512" component={Spotify} inheritViewBox/> <a
            href={entity.link_spotify} target="_blank">{makeSocialLink(entity.link_spotify)}</a></Typography>}
        {entity.link_apple &&
          <Typography variant={'body1'}><SvgIcon viewBox="0 0 136.46001 162.0049" component={AppleMusic}
                                                 inheritViewBox/> <a href={entity.link_apple}
                                                                     target="_blank">{makeSocialLink(entity.link_apple)}</a></Typography>}

        {!isMe &&
          <Box m={1}><FriendshipButton recipient={entity}/></Box>
        }
    </Grid>
};

export default UserCard;
