import React from "react";
import {IconButton} from "@mui/material";
import {Users} from "../object-actions/types/types";
import ApiClient from "../config/ApiClient";
import {Block} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../allauth/auth";

interface BlockProps {
    entity: Users;
    style: any
}

const BlockUser: React.FC<BlockProps> = ({entity, style}) => {
    const navigate = useNavigate();
    const me = useAuth()?.data?.user

    const handleStatusChange = async () => {
        if (!window.confirm("Are you sure you want to block and report this User")) {
            return false;
        }

        const formData: any = {
            "author": me.id,
            "recipient": entity.id,
            "status": 'blocked',
        };

        const response = await ApiClient.post(`/api/friendships/block-user`, formData);
        if (response.success && response.data) {
            navigate(`/friendships`)
        }
        if (response.error) {
            alert(response.error);
        }
    };

    return <IconButton style={style ?? null} onClick={() => handleStatusChange()}>
        <Block fontSize={'small'}/>
    </IconButton>
};

export default BlockUser;
