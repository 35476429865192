import React from 'react';
import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import FriendRequester from "../forms/FriendRequester";
import FriendsList from "../components/FriendsList";
import {getSafeAreaPadding} from "../utils";

interface FriendsProps {
    author?: number | string;
}

const Friends: React.FC<FriendsProps> = ({author}) => {

    const paddingTop = getSafeAreaPadding('paddingTop') + 10;

    return (
        <Box id={'TopItem'} style={{minHeight: '100vh'}} p={1} mt={7} pt={8}>

            <AppBar position={'fixed'} sx={{backgroundColor: 'background.default'}}
                    style={{width: '100%', paddingTop: paddingTop, backgroundImage: 'none', zIndex: 9}}>
                <Toolbar style={{width: '100%', backgroundColor: 'transparent', display: 'block'}}>
                    <FriendRequester/>
                </Toolbar>
            </AppBar>


            
            {/* <CardHeader
                avatar={<Avatar variant={'rounded'}><AddLink/></Avatar>}
                title={<Typography variant={'h3'}>Invite a Friend</Typography>}
                subheader={<Typography variant={'body2'}>Send an invite link to a friend</Typography>}
            /> */}

           
            <FriendsList author={author}/>
        </Box>
    );
};

export default Friends;
