import React, {ChangeEvent, useState} from 'react';
import {Typography} from "@mui/material";
import {Invites} from "../object-actions/types/types";
import ApiClient from "../config/ApiClient";
import {InviteButton} from "../forms/StyledFields";
import Avatar from "@mui/material/Avatar";
import CardHeader, {CardHeaderProps} from "@mui/material/CardHeader";
import {useNavigate} from "react-router-dom";
import {getFullName, makeAbsolute} from "../utils";
import {parsePagiFiltersFromURL} from "../contexts/GenericProvider";
import {Circle} from "@mui/icons-material";
import {useInvites} from "../contexts/InvitesProvider";

interface InviteProps {
    entity: Invites;
}

const InviteSentByMe: React.FC<InviteProps> = ({entity}) => {
    const [status, setStatus] = useState<string>(entity.status);
    const navigate = useNavigate();
    const {fetchList} = useInvites();

    const handleStatusChange = async (e: ChangeEvent<HTMLInputElement> | any) => {
        const {value} = e.target;

        if (value === 'withdrawn') {
            if (!window.confirm('Are you sure you want to withdraw this invitation?')) {
                return true;
            }
        }

        const response = await ApiClient.patch(`/api/invites/${entity.id}`, {status: value});
        if (response.success && response.data) {
            console.log(response.data);
            setStatus(value)

            const apiUrl = (entity.event?.id && window.location.pathname.indexOf('/events/') === 0) ? `/api/invites/by-event/${entity.event?.id}` : `/api/invites`
            const pagination = parsePagiFiltersFromURL()
            await fetchList(apiUrl, pagination)
        }
        if (response.error) {
            alert(response.error)
        }
    };

    const headerProps: Partial<CardHeaderProps> = {};

    const recipient = entity['recipient']

    headerProps.avatar =
        <Avatar src={typeof recipient.img === 'string' ? makeAbsolute(recipient.img) : recipient.str}
                alt={recipient['str']}
                onClick={() => navigate(`/users/${recipient.id}`)}/>

    if (status === 'accepted') {
        headerProps.title = <Typography variant={'subtitle1'}><em>{getFullName(recipient)}</em> accepted your invite {entity?.checkin &&
                    <Circle color={'success'} sx={{fontSize: 10, verticalAlign: 'baseline'}}/>}</Typography>
    } else if (status === 'requested') {
        headerProps.title = <Typography variant={'subtitle1'}><em>{getFullName(recipient)}</em> has requested to join</Typography>
    } else if (status === 'referred') {
        headerProps.title = <Typography variant={'subtitle1'}><em>{getFullName(recipient)}</em> was referred to join</Typography>
    } else if (status === 'seen') {
        headerProps.title = <Typography variant={'subtitle1'}><em>{getFullName(recipient)}</em> saw you invite link</Typography>
    } else {
        console.warn("STATUS AUTHOR MISMATCH!", entity)
        headerProps.title = <Typography variant={'subtitle1'}><em>{getFullName(recipient)}</em> was invited</Typography>
    }

    if (entity.status === 'withdrawn' || entity.status === 'seen') {
        headerProps.action = <InviteButton
            size={'small'}
            variant={'contained'}
            color={'inherit'}
            value={'invited'}
            onClick={handleStatusChange}
        >
            Resend Invitation
        </InviteButton>
    } else {
        headerProps.action = <InviteButton
            size={'small'}
            variant={'contained'}
            color={'inherit'}
            value={'withdrawn'}
            onClick={handleStatusChange}
        >
            Withdraw
        </InviteButton>
    }


    return <CardHeader {...headerProps} />
}

export default InviteSentByMe;
