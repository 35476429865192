import React from "react";
import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import EventInviter from "../components/EventInviter";
import FriendSelector from "../components/FriendSelector";
import {useParams} from "react-router-dom";
import InviteLinkButton from "../components/activities/InviteLinkButton";
import {getSafeAreaPadding} from "../utils";

interface InviteHomeProps {
}

const InviteHome: React.FC<InviteHomeProps> = () => {
    const params = useParams();
    const event_id = params.id ? parseInt(params.id) : 0;

    const paddingTop = getSafeAreaPadding('paddingTop') + 10;

    return (
        <Box id={'TopItem'} mt={15} style={{minHeight: '100vh'}} paddingX={'12px'}>
            <Box p={.6} mb={2}>
                <AppBar position={'fixed'} sx={{backgroundColor: 'background.default'}}
                        style={{width: '100%', paddingTop: paddingTop, backgroundImage: 'none', zIndex: 9}}>
                    <Toolbar className='flex flex-col w-full gap-[10px]' style={{width: '100%'}}>
                        {/* this appears as a search bar in this page*/}
                        <div className='text-[24px] font-bold text-white'>Send Invites</div>
                        <div className="w-full px-[12px]">
                            <EventInviter/>
                        </div>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box p={2}>
                <InviteLinkButton/>
            </Box>
            
            <FriendSelector event_id={event_id} />
        </Box>
    );
};

export default InviteHome;
