import React from "react";
import {Box,} from "@mui/material";
import SongRequestList from "./activities/SongRequestList";
import SongRequester from "./SongRequester";
import {getSafeAreaPadding} from "../utils";
import {AppBar, Toolbar} from "@mui/material";

interface EventSongRequestsProps {
    event_id?: string | number;
}

const EventSongRequests: React.FC<EventSongRequestsProps> = ({event_id}) => {

    const paddingTop = getSafeAreaPadding('paddingTop') + 60

    return (
        <Box style={{minHeight: '100vh'}} id={'TopItem'} pt={15} >
            <AppBar position={'fixed'} sx={{backgroundColor: 'background.default'}}
                    style={{width: '100%', backgroundImage: 'none', zIndex:9, paddingTop:paddingTop}}>
                <Toolbar style={{width: '100%', backgroundColor: 'transparent', display: 'block'}}>
                    <SongRequester event_id={event_id}/>
                </Toolbar>
            </AppBar>
            <SongRequestList event_id={event_id}/>
        </Box>
    );
}

export default EventSongRequests;
