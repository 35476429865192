import React, {useEffect, useState} from 'react'
import {ReactComponent as AppleMusic} from '../assets/applemusic.svg';
import {ButtonPill} from '../forms/StyledFields'
import {CircularProgress, SvgIcon} from "@mui/material";
import ApiClient from "../config/ApiClient";
import {useSnackbar} from "notistack";
import {useLocation} from "react-router-dom";
import {Close, WifiPassword} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

const AppleMusicIcon = <SvgIcon viewBox="0 0 136.46001 162.0049" component={AppleMusic} inheritViewBox/>

export interface AppleMusicConnectorProps {
    onReady?: (token: string, mkClient: any) => void;
}

const AppleMusicConnector: React.FC<AppleMusicConnectorProps> = ({
                                                                     onReady
                                                                 }) => {
    const [enabled, setEnabled] = useState(() => window.sessionStorage.getItem('musickitEnabled') === 'yes')
    const [token, setToken] = useState(window.sessionStorage.getItem('musicKitToken'))
    const {enqueueSnackbar, closeSnackbar } = useSnackbar();
    const location = useLocation()

    function installMusickit(cb: any) {
        const id = 'musickit-one-tap'
        const scr = document.getElementById(id)
        if (!scr) {
            const scr = document.createElement('script')
            scr.id = id
            scr.src = 'https://js-cdn.music.apple.com/musickit/v3/musickit.js'
            scr.async = true
            document.addEventListener('musickitloaded', async function () {
                cb()
            })

            document.body.appendChild(scr)
        } else {
            cb()
        }
    }

    function authorize() {
        const mkClient = window.MusicKit.getInstance();
        mkClient.authorize().then((token: string) => {
            console.log("token is: " + token);
            window.sessionStorage.setItem('musicKitToken', token)
            setToken(token);
            ApiClient.post(`/connectors/applemusic/store-token`, {token: token})
                .then((response) => {
                    if (response.success && response.data) {
                        enqueueSnackbar(`Apple Music successfully connected`, {
                            variant: 'info',
                            action: (snackbarKey) => (
                                <IconButton onClick={() => closeSnackbar(snackbarKey)}><Close /></IconButton>
                            ),
                            // Optional: Auto-hide the snackbar after 5 seconds
                            autoHideDuration: 3000,
                        })
                    }
                })
            if (onReady) {
                onReady(token, mkClient)
            }
        });
    }

    async function onMusickitInstalled() {
        try {
            // @ts-ignore
            await window.MusicKit.configure({
                developerToken: process.env.REACT_APP_MUSICKIT_TOKEN,
                storefrontId: 'us',
                app: {
                    name: 'Djmote',
                    build: '1.0.7',
                },
            });
            if (!enabled) {
                setEnabled(true)
            } else if (location.pathname.indexOf("applemusic") > -1) {
                authorize()
            }
        } catch (err: any) {
            console.error(err)
            window.alert(err.toString())
        }

    }

    function enable() {
        window.sessionStorage.setItem('musickitEnabled', 'yes')
        installMusickit(onMusickitInstalled)
    }

    function restart() {
        if (!token) {
            authorize();
        } else if (onReady) {
            const mkClient = window.MusicKit.getInstance();
            onReady(token, mkClient)
        }
    }

    useEffect(() => {
        if (!enabled || !window.MusicKit) {
            enable()
        } else if (location.pathname.indexOf("applemusic") > -1) {
            authorize()
        }
    }, [location]);

    if (!enabled) {
        return <ButtonPill
            fullWidth
            startIcon={AppleMusicIcon}
            variant={'outlined'}
            disabled={enabled}
            onClick={() => enable()}
            color={'inherit'}>Apple Music</ButtonPill>
    }

    const endIcon = location.pathname.indexOf("applemusic") > -1 ? <CircularProgress size={'small'} color={'primary'}/>
        : (token) ? <WifiPassword/> : undefined

    return <ButtonPill
        fullWidth
        variant={'outlined'}
        startIcon={AppleMusicIcon}
        onClick={() => restart()}
        endIcon={endIcon}
        color={'inherit'}>Apple Music</ButtonPill>


}

export default AppleMusicConnector