import React from 'react';
import {Grid, Typography} from '@mui/material';
import {Songs} from "../object-actions/types/types";
import {makeAbsolute} from "../utils";

interface SongProps {
    entity: Songs;
}

const Song: React.FC<SongProps> = ({entity}) => {
    return (
        <Grid item xs={6} style={{textAlign: 'center'}}>
            {entity.cover && (
                <img
                    height="150"
                    src={makeAbsolute(entity.cover)}
                    alt={`${entity.name} cover`}
                />
            )}
            <Typography gutterBottom variant="body2" component="div">
                {entity.name}, {entity.artist}
            </Typography>
            {entity.spotify_id && (
                <Typography variant="subtitle2" color="text.primary">
                    Spotify {entity.spotify_id}
                </Typography>
            )}
            {entity.apple_id && (
                <Typography variant="subtitle2" color="text.primary">
                    Apple Music {entity.apple_id}
                </Typography>
            )}
        </Grid>
    );
};

export default Song;
