import React from 'react';
import { ReactComponent as RemoveSelectedMemberButton } from '../assets/removeSelectedMemberButton.svg';
    
interface FriendsProps {
    author?: number | string;
    selectedFriendsFullData: { [key: string | number]: any }
    handleSelect: (id: number | string, has_friendship: boolean) => void;
}

const HorizontalFriendsToInviteList: React.FC<FriendsProps> = ({author, selectedFriendsFullData, handleSelect}) => {
    const hasFriends = Object.keys(selectedFriendsFullData).length > 0;
    return (
        <div 
            className="transition-[height] duration-300 ease-in-out overflow-hidden"
            style={{ height: hasFriends ? '110px' : '0px' }}
        >
            <div className="flex flex-row overflow-x-auto px-[12px] gap-[10px] pb-[0px]">
                {Object.keys(selectedFriendsFullData).map((key, i) => (
                    <div 
                        key={`friend-to-invite-${i}`} 
                        className='flex flex-col items-center gap-[12px] transition-all duration-300 opacity-100 translate-y-0 flex-shrink-0 relative'
                        style={{
                            animation: hasFriends ? 'slideIn 0.3s ease-out' : 'slideOut 0.3s ease-out'
                        }}
                    >
                        <button onClick={() => handleSelect(key, true)} className="absolute top-[4px] right-[4px]">
                            <RemoveSelectedMemberButton/>
                        </button>
                        {/* @ts-ignore */}
                        <img src={selectedFriendsFullData[key]?.author?.img} alt={selectedFriendsFullData[key]?.author?.entity?.full_name} className='size-[80px] rounded-full'/>
                        <div className='text-[16px] font-normal whitespace-nowrap'>
                            {/* @ts-ignore */}
                            {selectedFriendsFullData[key]?.author?.str?.charAt(0).toUpperCase() + selectedFriendsFullData[key]?.author?.str?.slice(1)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};


export default HorizontalFriendsToInviteList;
