import React, {useState} from 'react';
import {CircularProgress, Divider} from "@mui/material";
import CardHeader, {CardHeaderProps} from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import {useAuth} from "../allauth/auth";
import {FriendButton} from "../forms/StyledFields";
import ApiClient from "../config/ApiClient";
import {getFullName, getUsername, makeAbsolute} from "../utils";
import {Friendships, Invites} from "../object-actions/types/types";
import {useSnackbar} from "notistack";
import {Check, DisabledByDefault} from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {parsePagiFiltersFromURL} from "../contexts/GenericProvider";
import {fetchList} from "../hooks/useQuery";
import {useFriends} from "../contexts/FriendsProvider";
import { ReactComponent as CheckIcon } from '../assets/checkicon.svg'

interface FriendInviteProps {
    entity: Friendships;
    invite?: Invites;
    event_id: string | number;
    onClick: (id: number | string, has_friendship:boolean) => void;
    selected: boolean;
}

const FriendInvite: React.FC<FriendInviteProps> = ({entity, event_id, invite, onClick, selected = false}) => {
    const [syncing, setSyncing] = useState<boolean>(false);
    const me = useAuth()?.data?.user
    const {enqueueSnackbar} = useSnackbar()
    const initPagi = parsePagiFiltersFromURL();
    const {fetchList} = useFriends()
    const other = entity['recipient'] && entity['author'].id === me.id ? entity['recipient'] : entity['author']

    const sendInvite = async () => {
        if (onClick) {
            return onClick(other.id, entity.status === 'accepted')
        }
        setSyncing(true)
        const response = await ApiClient.post(`/api/invites`, {
            status: entity.status === 'accepted' ? 'invited' : 'referred', // if friends auto accept, otherwise refer
            event: event_id,
            author: me.id,
            recipient: other.id
        });
        setSyncing(false)
        if (response.success && response.data) {
            enqueueSnackbar(`You invited ${getUsername(entity)}`)
            await fetchList(`/api/invites/by-event/${event_id}`, initPagi)
        }
        if (response.error) {
            enqueueSnackbar(response.error)
        }
    };

    const headerProps: Partial<CardHeaderProps> = {};

    if (typeof other.img === 'string') {
        headerProps.avatar = <Avatar sx={{minWidth:'50px', minHeight:'50px'}} onClick={sendInvite} src={makeAbsolute(other.img)} alt={other['str']}/>
    } else {
        headerProps.avatar = <Avatar sx={{minWidth:'50px', minHeight:'50px'}} onClick={sendInvite} src={other['str']} alt={other['str']} />
    }


    headerProps.title = getFullName(other)
    headerProps.subheader = getUsername(other)
    if (invite?.status) {
        headerProps.action = <div className='text-white font-normal text-[12px] flex flex-row justify-center items-center gap-[2px] bg-color-grayedOutText bg-formBackground-friendInvited w-[82px] h-[27px] rounded-[99px]'>
            <div>
                {invite?.status === 'accepted' && 'Invited'}
                {invite?.status === 'invited' && 'Pending'}
                {invite?.status === 'referred' && 'Requested'}
            </div>
            {invite?.status === 'accepted' && <CheckIcon className='size-[14px] bg-formBackground-friendInvited'/>}
        </div>
        
        // <FriendButton
        //     size={'small'}
        //     variant={'outlined'}
        //     color={'inherit'}
        //     disabled={true}
        //     startIcon={invite?.status === 'accepted' || invite?.status === 'pending' || invite?.status === 'requested' ?
        //         <CheckCircleIcon color="primary" /> : <DisabledByDefault />}
        // >
        //     {invite?.status}
        // </FriendButton>
    } else {
        headerProps.action = 
        <div 
            className={`size-[27px] rounded-[16px] border-[1px] border-white flex items-center justify-center self-center my-auto ${selected ? 'bg-primary' : ''}`}
            // disabled={syncing || invite?.status === 'declined'}
            // onClick={sendInvite}
        >
            {selected && <CheckIcon className='w-[14px] h-[14px]'/>}
            {syncing && <CircularProgress sx={{color: 'white', size: '8px'}}/>}
        </div>
        // <FriendButton
        //     sx={{   
        //         width: '27px',
        //         height: '27px',
        //         borderRadius: '16px',
        //         border: '1px solid white',
        //         minWidth: '27px',
        //         minHeight: '27px',
        //     }}
        //     //size={'small'}
        //     //variant={'outlined'}
        //     ///color={'inherit'}
        //     disabled={syncing || invite?.status === 'declined'}
        //     startIcon={selected ? <CheckCircleIcon color="primary"/> : (syncing ?
        //         <CircularProgress size={'small'} color={'primary'}/> : undefined)}
        //     onClick={sendInvite}
        // >

        // </FriendButton>
    }

    return (
            <button className='flex flex-row justify-between items-center w-full px-[14px]' onClick={sendInvite} disabled={syncing || invite?.status === 'declined'}>
                <div className='flex flex-row justify-center items-center gap-[10px]'>
                    <div style={{pointerEvents: 'none'}}>
                        {headerProps.avatar}
                    </div>
                    <div className='flex flex-col justify-center items-start'>
                        <div className='text-white text-[16px] font-normal'>
                            {headerProps.title}
                        </div>
                        <div className="text-[#6C6C6C] text-[12px] font-bold leading-tight">
                            {headerProps.subheader}
                        </div>
                    </div>
                </div>

                <div>
                    {headerProps.action}
                </div>
            </button>
    )

    
    
}

export default FriendInvite;



{/* <CardHeader 
    style={{
        width: '100%', 
    }}  
    {...headerProps} 
/> */}
