import React from 'react';
import {Box, IconButton, Modal, Typography} from '@mui/material';
import {ArrowBack} from "@mui/icons-material";

interface FullScreenModalProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    children: React.ReactNode;
}

const FullScreenModal: React.FC<FullScreenModalProps> = ({open, onClose, title, children}) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    bgcolor: 'background.paper',
                    display: 'flex',
                    flexDirection: 'column',
                    boxSizing: 'border-box',
                }}
            >
                {title ?
                    <Box p={1} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <IconButton onClick={onClose}>
                            <ArrowBack/>
                        </IconButton>
                        <Typography variant="h6" mr={1}>{title}</Typography>
                    </Box>
                    :
                    <IconButton onClick={onClose} style={{position: 'absolute', left: 10, top: 10, zIndex: 999}}>
                        <ArrowBack/>
                    </IconButton>
                }
                <Box sx={{flex: 1, overflowY: 'auto', mb: 2}}>
                    {children}
                </Box>
            </Box>
        </Modal>
    );
};

export default FullScreenModal;
