import React from 'react';
import {Box} from '@mui/material';
import {Playlists} from "../object-actions/types/types";
import {makeAbsolute} from "../utils";
import Typography from "@mui/material/Typography";

interface PlaylistProps {
    entity: Playlists;
}

const Playlist: React.FC<PlaylistProps> = ({entity}) => {

    const img = (entity.image) ? makeAbsolute(entity.image) : entity.remote_image;

    return <Box>
        {img &&
          <img src={img}
               style={{
                   width: '100%',
                   height: 'auto',
                   borderRadius: 8
               }}/>}

        <Typography variant={'body1'} mt={1} style={{textAlign:'center'}}>{entity.name}</Typography>
    </Box>
};

export default Playlist;
