import React from 'react';
import {Avatar, AvatarGroup} from '@mui/material';
import {RelEntity} from "../object-actions/types/types";
import {makeAbsolute} from "../utils";
import {useNavigate} from "react-router-dom";

interface RelEntityAvatarsProps {
    entities: RelEntity[];
    event_id?: string | number;
}

const AvatarsGrouped: React.FC<RelEntityAvatarsProps> = ({entities, event_id}) => {
    const navigate = useNavigate();

    if (entities.length === 0) {
        return null;
    }

    if (entities.length === 1) {
        const entity = entities[0];
        return (
            <Avatar style={{border: "2px solid #000"}} alt={entity.str}
                    onClick={() => navigate(`/events/${event_id}/invites`)}
                    src={entity.img ? makeAbsolute(entity.img) : undefined}>
                {entity.str.charAt(0)}
            </Avatar>
        );
    }

    return (
        <AvatarGroup style={{flexDirection: 'row'}} max={4} onClick={() => navigate(`/events/${event_id}/invites`)}>
            {entities.map((entity) => (
                <Avatar style={{border: "2px solid #000"}} key={entity.id} alt={entity.str}
                        src={entity.img ? makeAbsolute(entity.img) : undefined}>
                    {entity.img ? null : entity.str.charAt(0)}
                </Avatar>
            ))}
        </AvatarGroup>
    );
};

export default AvatarsGrouped;
