import React from 'react';
import {Events, RelEntity} from "../object-actions/types/types";
import ApiClient from "../config/ApiClient";
import {useAuth} from "../allauth/auth";
import {ButtonPill} from "./StyledFields";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";

interface CheckinProps {
    event: Events;
    checkin?: RelEntity
    format?: string
}

const CheckinButton: React.FC<CheckinProps> = ({event, checkin, format}) => {
    const me = useAuth()?.data?.user
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar()
    const [status, setStatus] = React.useState(checkin ? checkin.str : '');

    // Remove comments to turn asking for location back on when entering an event
    /*
    const getLocation = (): Promise<{ latitude: number; longitude: number } | false | null> => {
        return new Promise((resolve) => {
            if (!navigator.geolocation) {
                resolve(null);
            } else {
                navigator.geolocation.getCurrentPosition(
                    (pos) => {
                        resolve({
                            latitude: pos.coords.latitude,
                            longitude: pos.coords.longitude,
                        });
                    },
                    (error) => {
                        if (error.code === error.PERMISSION_DENIED) {
                            resolve(false);
                        } else {
                            resolve(null);
                        }
                    },
                    {
                        enableHighAccuracy: true,
                        timeout: 5000,
                        maximumAge: 0,
                    }
                );
            }
        });
    };
    */
    const handleSelect = async (status = 'entered') => {
        // Remove comments to turn asking for location back on when entering an event
        /*
        const mylocation = await getLocation();

        if (mylocation === false) {
            enqueueSnackbar("Location permission denied.");
            return;
        }

        if (mylocation === null) {
            enqueueSnackbar("Error retrieving location.");
            return;
        }
        */
        const formData: any = {
            "status": status,
            "author": me.id,
            "event": event.id,
            //"coordinate": mylocation
        };

        let response = await ApiClient.post(`/api/event_checkins/toggle-checkin`, formData);
        if (response.success && response.data) {
            enqueueSnackbar(`Checked into ${event.name}`)
            // @ts-ignore
            setStatus(response.data.status)
        }

        navigate(`/events/${event.id}/checked-in`)

    };

    const props:any =  {variant:'contained'}
    if (format === 'text') {
        props.variant = 'text';
        props.size = 'small'
    }

    return <React.Fragment>
        {(status === '' || status === 'left') &&
            <ButtonPill
                {...props}
                onClick={() => handleSelect('entered')}
            >Enter</ButtonPill>
        }

    </React.Fragment>
};

export default CheckinButton;
