import React, {useEffect, useState} from 'react';
import GenericForm from '../object-actions/forms/GenericForm';
import {Box, CircularProgress, Grid, Typography} from '@mui/material';
import {EntityTypes, FieldTypeDefinition, NAVITEMS, parseFormURL, TypeFieldSchema} from '../object-actions/types/types'
import {useLocation, useParams} from "react-router-dom";
import ApiClient from "../config/ApiClient";
//import UserView from '../screens/UserView';


const ProfileForm = () => {

    const {id} = useParams();
    // @ts-ignore
    const [entity, setEntity] = useState<EntityTypes>({id: 0});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const location = useLocation();

    const target = parseFormURL(location.pathname)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            if (target) {
                const result = await ApiClient.get(`/api/${target.object}/${target.id}${location.search}`)
                if (result.success && result.data) {
                    setEntity(result.data as EntityTypes)
                    setLoading(false)
                } else {
                    setError(result.error || "Unknown Error");
                    setLoading(false)
                }
            } else {
                setError('Invalid form URL pattern: ' + JSON.stringify(target));
                setLoading(false)
            }
        };

        if (target && target.verb === 'edit') {
            fetchData();
        } else {
            setLoading(false)
        }


    }, [id, location.pathname, location.search]);

    if (!target) {
        return <Typography variant={'h6'}>Invalid URL pattern</Typography>
    }
    const hasUrl = NAVITEMS.find(nav => nav.screen === `/${target.object}`);
    if (!hasUrl) return <Typography>Unknown Type</Typography>

    const fields: FieldTypeDefinition[] = []
    const allowed = ['profile_picture', 'first_name', 'last_name', 'username', 'bio',  'phone', 'link_ig', 'link_apple',  'link_spotify']
    allowed.forEach((field_name) => {
        fields.push(TypeFieldSchema[hasUrl.type][field_name])
    })

    return (
        <Box p={1} mt={8}>
            {error.length > 0 ?
                <Grid container justifyContent="center" alignItems="center">
                    <Typography variant='subtitle1'>{error}</Typography>
                </Grid>
                :
                loading ?
                    <Grid container justifyContent="center" alignItems="center">
                        <CircularProgress/>
                    </Grid>
                    :
                        <GenericForm
                            fields={fields}
                            navItem={hasUrl}
                            original={entity}
                        />
            }
        </Box>

    );
};

export default ProfileForm;
