import React from "react";
import {Invites} from "../object-actions/types/types";
import {useAuth} from "../allauth/auth";
import InviteSent from "./InviteSent";
import InviteToMe from "./InviteToMe";
import InviteSentByMe from "./InviteSentByMe";

interface InviteProps {
    entity: Invites;
}

const Invite: React.FC<InviteProps> = ({entity}) => {
    const me = useAuth()?.data?.user;

    if (me.id === entity["recipient"].id) {
        return <InviteToMe entity={entity}/>
    } else if (entity.status === 'referred' || entity.status === 'requested') {
        return <InviteSent entity={entity} />
    } else if (me.id === entity["author"].id) {
        return <InviteSentByMe entity={entity}/>
    }

    return <InviteSent entity={entity} />
};

export default Invite;
