import {ApiListResponse, EntityTypes} from "../../object-actions/types/types";
import ApiClient, {HttpResponse} from "../../config/ApiClient";
import {PagiFilters} from "../../contexts/GenericProvider";

export function buildApiUrl(apiPath: string, offset: number, limit: number) {
    let apiUrl = `${apiPath}`
    if (typeof offset === 'number' && typeof limit === 'number') {
        if (apiUrl.indexOf('?') > -1) {
            apiUrl += `&`
        } else {
            apiUrl += `?`
        }
        apiUrl += `offset=${offset}&limit=${limit}`
    }
    return apiUrl
}

export const fetchList = async (apiPath: string, pagiFilters: PagiFilters): Promise<HttpResponse<ApiListResponse<EntityTypes>>> => {
    let apiUrl = buildApiUrl(apiPath, pagiFilters.offset, pagiFilters.limit)
    return await ApiClient.get<ApiListResponse<EntityTypes>>(apiUrl)
};
