import React, {useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {Avatar, Divider, Fab, Grid, useTheme} from "@mui/material";
import {useFocusedContext} from "../contexts/FocusedProvider";
import {Events} from "../object-actions/types/types";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {useNavDrawer} from "../NavDrawerProvider";
import EventMenuItems from "../components/EventMenuItems";
import CardHeader from "@mui/material/CardHeader";
import {StyledDrawer} from "../forms/StyledFields";
import {formatDateTime, getSafeAreaPadding, makeAbsolute} from "../utils";
import {useAuth} from "../allauth/auth";
import {parsePagiFiltersFromURL} from "../contexts/GenericProvider";
import {useInvites} from "../contexts/InvitesProvider";

interface EventMenuProps {
    viewMode?: string;
}

export const EventMenu: React.FC<EventMenuProps> = ({viewMode}) => {
    const params = useParams();
    const id = params.id ? parseInt(params.id) : 0;
    const location = useLocation()
    const {navDrawerWidth, setNavDrawerWidth} = useNavDrawer();
    const me = useAuth()?.data?.user;
    const initPagi = parsePagiFiltersFromURL();
    const {fetchList} = useInvites();

    const {
        focusedEvent,
        fetchEvent,
        fetchInviteCounts
    } = useFocusedContext()

    useEffect(() => {
        if (id > 0 && (location.pathname.indexOf('/events') > -1 || location.pathname.indexOf('/e/') === 0 || location.pathname.indexOf('/i/') === 0)) {
            fetchEvent(id)
            fetchInviteCounts(id)
            fetchList(`/api/invites/by-event/${id}`, initPagi)
        }
    }, [id, location.pathname]);

    if (!focusedEvent || !id) return null;

    const canManage = focusedEvent.author.id === me.id || (focusedEvent.cohosts && focusedEvent?.cohosts.findIndex(rel => rel.id === me.id) > -1);
    if (!canManage) return <React.Fragment></React.Fragment>;

    const handleDrawerOpen = () => {
        setNavDrawerWidth(300);
    };

    const handleDrawerClose = () => {
        setNavDrawerWidth(0);
    };


    const paddingTop = getSafeAreaPadding('paddingTop');

    return <React.Fragment>
        <Fab
            id={"DrawerMenu"}
            color="inherit"
            size="small"
            sx={{
                borderColor: 'white',
                borderWidth: 1,
                position: 'fixed', right: 10,
                top: paddingTop,
                backgroundRepeat: 'no-repeat',
                backgroundColor: 'transparent',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
            }}
            aria-label="open drawer"
            onClick={handleDrawerOpen}
        >
            <MenuIcon color={'inherit'} fontSize={'small'}/>

        </Fab>
        <StyledDrawer
            anchor="right"
            variant="temporary"
            open={navDrawerWidth > 0}
            onClose={handleDrawerClose}
            sx={{
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: navDrawerWidth
                },
            }}
        >
            <Grid container justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
                <CardHeader
                    title={focusedEvent.name}
                    subheader={viewMode === 'active' || window.location.pathname.indexOf('/active') > -1 ? `Ends ${formatDateTime(focusedEvent.ends)}` : `Starts ${formatDateTime(focusedEvent.starts)}`}
                    avatar={<Avatar src={makeAbsolute(focusedEvent.cover)}/>}/>
                <IconButton onClick={handleDrawerClose}>
                    <ChevronRightIcon/>
                </IconButton>
            </Grid>
            <Divider/>
            <EventMenuItems entity={focusedEvent as Events} canManage={canManage}/>
        </StyledDrawer>
    </React.Fragment>

};

export default EventMenu;
