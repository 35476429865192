import React, {useEffect, useState} from 'react';
import {ApiListResponse, SocialPlaylists} from "../object-actions/types/types";
import {Box, CircularProgress, Grid, IconButton, LinearProgress, Typography} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {ButtonPill} from "../forms/StyledFields";
import ApiClient from "../config/ApiClient";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import AppleMusicConnector from "../components/AppleMusicConnector";

const PlaylistsApple: React.FC = () => {

    const params = useParams();
    const event_id = params.id ? parseInt(params.id) : 0;
    const navigate = useNavigate();

    const location = useLocation();
    const state = location.state;
    let mkClient = false;

    const [selectedPlaylists, setSelectedPlaylists] = useState<Set<string>>(new Set());
    const [token, setToken] = useState(state?.token ?? window.sessionStorage.getItem('musicKitToken'))
    const [syncing, setSyncing] = useState<boolean>(false);
    const [playlists, setPlaylists] = useState<SocialPlaylists[] | null>(null)

    function onAuthorized(tok: string, mkClient: any) {
        setToken(tok)
        loadPlaylists()
    }

    async function loadPlaylists() {
        if (!mkClient) {
            mkClient = window.MusicKit.getInstance();
        }
        // @ts-ignore
        const result = await mkClient.api.music('v1/me/library/playlists', {limit: 100, offset: 0});
        console.log(result)
        const results: SocialPlaylists[] = result.data.data.map((item: any) => ({
            name: item.attributes.name,
            social_source: 'apple',
            id: item.id,
            remote_image: item.attributes?.artwork ? item.attributes.artwork.url.replace("{w}x{h}", "200x200") : null, // Adjust if necessary
            tracks_count: item.attributes.trackCount ?? 0,
            _type: item.type
        }));
        setPlaylists(results)

    }

    useEffect(() => {
        if (window.MusicKit) {
            loadPlaylists()
        }
    }, [window.MusicKit]);

    if (!window.MusicKit) return <Box mt={5} p={2} style={{textAlign: 'center'}}>
        <AppleMusicConnector onReady={onAuthorized}/>
    </Box>

    const handleSelect = (id: string) => {
        setSelectedPlaylists(prev => {
            const newSelected = new Set(prev);
            if (newSelected.has(id)) {
                newSelected.delete(id);
            } else {
                newSelected.add(id);
                newSelected.add(id);
            }
            return newSelected;
        });
    };

    const handlePlaylistSync = async () => {
        setSyncing(true)
        const response = await ApiClient.post(`/connectors/applemusic/playlists/sync`, {
            event_id: event_id,
            pids: Array.from(selectedPlaylists),
            token: token
        });

        setSyncing(false)

        if (response.error) {
            alert(response.error)
        } else {
            navigate(`/events/${event_id}`)
            console.log(response.data as ApiListResponse)
        }
    }

    return (
        <Box p={2} mb={5}>

            <Typography mt={5} variant={'h3'} gutterBottom={true} style={{textAlign: 'center'}}>Select
                Playlists</Typography>

            <Grid container spacing={2} mb={2}>
                {!playlists ?
                    <LinearProgress/>
                    : playlists.map((playlist) => {
                        const isSelected = selectedPlaylists.has(playlist.id);
                        return (
                            <Grid item xs={6} sm={4} md={3} key={playlist.id} style={{textAlign: 'center', position: 'relative'}}>
                                <Box onClick={() => handleSelect(playlist.id)}
                                     sx={{position: 'relative', cursor: 'pointer'}}>
                                    <img src={playlist.remote_image ? playlist.remote_image : `/events-background.png`}
                                         style={{
                                             width: '100%',
                                             height: playlist.remote_image ? 'auto' : '150px',
                                             borderRadius: 8,
                                             opacity: isSelected ? 0.7 : 1
                                         }}/>
                                    {isSelected && (
                                        <IconButton sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            fontSize: '3rem',
                                        }}>
                                            <CheckCircleIcon fontSize="inherit"/>
                                        </IconButton>
                                    )}
                                </Box>
                                <Typography variant={'subtitle2'}>{playlist.name}</Typography>
                            </Grid>
                        );
                    })}
            </Grid>
            <Box style={{textAlign: 'center'}}>
                <ButtonPill
                    disabled={!playlists || selectedPlaylists.size === 0}
                    startIcon={!playlists || selectedPlaylists.size === 0 ? <CircularProgress size={'small'} color={'primary'}/> : undefined}
                    variant={'contained'}
                    onClick={handlePlaylistSync}
                >Confirm</ButtonPill>
            </Box>
        </Box>
    );
};

export default PlaylistsApple;
