import React, {useEffect} from 'react';
import {Box, Typography} from "@mui/material";
import {Events} from "../object-actions/types/types";
import {isCurrent, makeAbsolute} from "../utils";
import {BackgroundImage} from "./FullscreenBg";
import {ButtonPill} from "../forms/StyledFields";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PersonIcon from '@mui/icons-material/Person';
import InvitesSummary from "./InvitesSummary";
import {RecentNotifications} from "../screens/ActivityList";
import SongRequestList from "./activities/SongRequestList";
import SongRecommendations from "./SongRecommendations";
import {Link, useLocation} from "react-router-dom";
import {Circle} from "@mui/icons-material";

interface MyEventProps {
    entity: Events;
}

const allowedTabs = ['recommended', 'requested', 'activity']

const MyEvent: React.FC<MyEventProps> = ({entity}) => {
    const location = useLocation();
    let defaultTab = location.pathname.split('/').pop() as string
    if (!allowedTabs.includes(defaultTab)) {
        defaultTab = 'recommended'
    }
    const [selectedTab, setSelectedTab] = React.useState(defaultTab);
    const handleChange = (event: React.SyntheticEvent, newVal: string) => {
        setSelectedTab(newVal);
    };

    useEffect(() => {
        defaultTab = location.pathname.split('/').pop() as string
        if (allowedTabs.includes(defaultTab)) {
            setSelectedTab(defaultTab)
        }
    }, [location.pathname]);

    useEffect(() => {
        if (window.flutter_inappwebview) {
            document.body.classList.remove('useSafeArea');
            window.flutter_inappwebview.callHandler('useSafeArea', false);
            return () => {
                document.body.classList.add('useSafeArea');
                window.flutter_inappwebview.callHandler('useSafeArea', true);
            };
        }
    }, []);

    const isActive = isCurrent(entity.starts, entity.ends)

    return <Box sx={{minHeight: '100vh'}}>

        <Box style={{position: 'relative'}} p={0} m={0}>

            {/* @ts-ignore */}
            <BackgroundImage imageurl={makeAbsolute(entity.cover)}
                             position={'absolute'}
                             opacity={1} blur={20}/>

            <Box id={'TopItem'} style={{position: 'relative', textAlign: 'center', zIndex: 9}} p={1} pt={8}>

                <Box style={{position: 'relative'}} pl={11} pr={11}>
                    <div
                        style={{
                            width: '100%',
                            borderRadius: 16,
                            paddingTop: '100%', // This creates a square container
                            backgroundImage: `url(${makeAbsolute(entity.cover)})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            margin: 'auto auto 20px auto',
                        }}
                        aria-label="event flyer"
                    />
                </Box>
                <Typography mt={2} variant={'h2'}>{entity.name} {isActive &&
                    <Circle color={'success'} sx={{fontSize: 11, verticalAlign: 'middle'}}/>}
                </Typography>

                {entity.description &&
                    <Typography variant={'body1'} gutterBottom={true}>{entity.description}</Typography>
                }

                <InvitesSummary entity={entity} canManage={true}/>
            </Box>
        </Box>

        <Tabs variant="fullWidth" value={selectedTab} onChange={handleChange} centered>
            <Tab label="Recommended" value={"recommended"} icon={<MusicNoteIcon/>} sx={{textTransform: 'none'}}/>
            <Tab label="Requested" value={"requested"} icon={<QueueMusicIcon/>} sx={{textTransform: 'none'}}/>
            <Tab label="Activity" value={"activity"} icon={<PersonIcon/>} sx={{textTransform: 'none'}}/>
        </Tabs>

        <Box style={{zIndex: 9}}>
            {selectedTab === 'activity' ?
                <RecentNotifications event_id={entity.id}/>
                : (selectedTab === 'requested' ?
                    <Box style={{paddingBottom: 40}}>
                        <SongRequestList event_id={entity.id}/>
                        <Box style={{position: 'fixed', bottom: 65, left: 0, right: 0, textAlign: 'center'}}>
                            <ButtonPill
                                component={Link} to={`/events/${entity.id}/request-song`}
                                variant={"contained"}
                                color={"primary"}
                            >
                                Request a Song
                            </ButtonPill>
                        </Box>
                    </Box>
                    :
                    <SongRecommendations event_id={entity.id}/>)
            }
        </Box>
    </Box>

}

export default MyEvent;
