import {useConfig} from '../auth'
import {redirectToProvider} from '../lib/allauth'
import {Grid, SvgIcon} from '@mui/material'
import React from "react";
import {ReactComponent as Spotify} from '../../assets/spotifywhite.svg';
import {ReactComponent as AppleMusic} from '../../assets/applemusic.svg';
import {ReactComponent as Apple} from '../../assets/apple.svg';
import {ReactComponent as Google} from '../../assets/google.svg';
import {ButtonPill} from "../../forms/StyledFields";
import {WifiPassword} from "@mui/icons-material";
import GoogleInAppButton from "../../components/inapp/GoogleInAppButton";
import AppleMusicConnector from "../../components/AppleMusicConnector";

interface ProviderListProps {
    callbackURL: string;
    process: any;
    accounts?: any;
}

// it seems this component is trying to import a list of music services and dynamically display all of them, but for now Apple Music and Spotify are hardcoded. 

const ProviderList: React.FC<ProviderListProps> = ({callbackURL, process, accounts}) => {
    const config = useConfig()
    const providers = config.data.socialaccount.providers
    if (!providers.length) {
        return null
    }

    function getIcon(provider: string) {
        if (provider === 'apple') {
            return <SvgIcon fontSize={"large"} component={Apple} inheritViewBox/>
        } else if (provider === 'applemusic') {
            return <SvgIcon viewBox="0 0 136.46001 162.0049" style={{width: 30, height: 30}} component={AppleMusic} inheritViewBox/>
        } else if (provider === 'google') {
            return <Google/>
        } else if (provider === 'spotify') {
            return <SvgIcon viewBox="0 0 496 512" style={{width: 30, height: 30}} component={Spotify} inheritViewBox/>
        }
        return null;
    }

    function getProviderName(provider: string) {
        if (provider === 'apple') {
            return 'Apple Music'
        } else if (provider === 'applemusic') {
            return 'Apple Music'
        } else if (provider === 'google') {
            return 'Google'
        } else if (provider === 'spotify') {
            return 'Spotify'
        }
        return provider
    }


    return (
        <Grid container gap={2}>
            {providers.map((provider: any) => {
                if (localStorage.getItem("appOS") && provider.id === 'google') return false;

                const connected = accounts?.find((a: any) => a.provider.id === provider.id)
                if (localStorage.getItem("appOS") && provider.id === 'google') {
                    if (window.location.search.indexOf('useOauth') > -1) {
                        return <GoogleInAppButton isConnected={connected} />
                    } else {
                        return null;
                    }
                }

                if (provider.id === 'applemusic') {
                    return <AppleMusicConnector />
                }

                // @ts-ignore
                return (
                    <Grid container direction="column" gap={2} px={2}>
                        <ButtonPill
                            startIcon={getIcon('applemusic')}
                            endIcon={connected ? <WifiPassword/> : undefined}
                            key={'applemusic'}
                            fullWidth
                            variant={'outlined'}
                            color={'inherit'}
                            onClick={() => redirectToProvider(provider.id, callbackURL, process)}
                            sx={{
                                borderRadius: '15px',
                                height: '55px',
                                color: 'black',
                                border: 'none', 
                                background: 'white',
                                fontSize: '20px',
                                justifyContent: 'flex-start',
                                paddingLeft: '24px',
                                gap: '10px',
                                fontWeight: 'bold',
                                '&:hover': {
                                    background: 'white',
                                },
                                '&:active': {
                                    background: 'white'
                                }
                            }}
                        >Connect {getProviderName('applemusic')}
                        </ButtonPill>
                        <ButtonPill
                            startIcon={getIcon('spotify')}
                            endIcon={connected ? <WifiPassword/> : undefined}
                            key={'spotify'}
                            fullWidth
                            variant={'outlined'}
                            color={'inherit'}
                            onClick={() => redirectToProvider(provider.id, callbackURL, process)}
                            sx={{
                                borderRadius: '15px',
                                height: '55px',
                                color: 'black',
                                border: 'none', 
                                background: 'white',
                                fontSize: '20px',
                                justifyContent: 'flex-start',
                                paddingLeft: '24px',
                                gap: '10px',
                                fontWeight: 'bold',
                                '&:hover': {
                                    background: 'white',
                                },
                                '&:active': {
                                    background: 'white'
                                }
                            }}
                        >Connect {getProviderName('spotify')}
                        </ButtonPill>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default ProviderList;
