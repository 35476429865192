import React, {useEffect, useState} from 'react';
import {ButtonPill} from "../forms/StyledFields";
import CardHeader from "@mui/material/CardHeader";
import {Events, Invites, RelEntity} from "../object-actions/types/types";
import {Typography} from "@mui/material";
import AvatarsGrouped from "./AvatarsGrouped";
import {useFocusedContext} from "../contexts/FocusedProvider";
import {useNavigate} from "react-router-dom";
import {parsePagiFiltersFromURL} from "../contexts/GenericProvider";
import {useInvites} from "../contexts/InvitesProvider";

interface InvitesSummaryCtaProps {
    entity: Events;
    format?: string;
    canManage: boolean;
}

const InvitesSummary: React.FC<InvitesSummaryCtaProps> = ({entity, format, canManage}) => {
    const navigate = useNavigate();
    const [firstFewStr, setFirstFewStr] = useState('');
    const [avatars, setAvatars] = useState<RelEntity[]>([]);
    const {inviteCounts} = useFocusedContext()
    const pagination = parsePagiFiltersFromURL();
    const guestList = useInvites(`/api/invites/by-event/${entity.id}`, pagination);

    const hosts = Array.isArray(entity.cohosts) && entity.cohosts.length > 0
        ? [entity.author].concat(entity.cohosts)
        : [entity.author]


    useEffect(() => {
        if (inviteCounts && guestList.apiResponse && guestList.apiResponse?.count > 0) {

            const names = []
            const avatars = []
            const invites = guestList.apiResponse?.results as Invites[];
            for (let i = 0; i < invites.length && i < 4; i++) {
                const isHost = hosts.findIndex(h => h.id === invites[i].recipient.id)
                if (isHost > 0) {
                    hosts.splice(isHost, 1)
                }
                if (invites[i].status === 'accepted') {
                    names.push(invites[i].recipient.str)
                    avatars.push(invites[i].recipient)
                }
            }
            if (avatars.length < 4 && hosts.length > 0) {
                for (let i in hosts) {
                    names.push(hosts[i].str)
                    avatars.push(hosts[i])
                }
            }

            setAvatars(avatars)
            let finalStr = names.join(', ')
            if (inviteCounts.accepted > 0 && inviteCounts.accepted - names.length > 0) {
                finalStr += `, +${inviteCounts.accepted - names.length} more`
            }
            setFirstFewStr(finalStr)
        }
    }, [inviteCounts, guestList.apiResponse]);

    const handleOpen = () => {
        if (canManage) {
            navigate(`/events/${entity.id}/invite`)
        } else {
            navigate(`/events/${entity.id}/invites`)
        }
    };

    if (guestList.error) {
        return <Typography variant='h4'>{guestList.error}</Typography>
    }

    if (inviteCounts.accepted === 0) {
        return <div style={{textAlign:'center'}} ><ButtonPill onClick={handleOpen} variant={'contained'}>Send Invites</ButtonPill></div>
    }

    if (format === 'justavatars') return <AvatarsGrouped event_id={entity.id} entities={avatars}/>

    return <CardHeader
        sx={{backgroundColor: 'background.paper', textAlign: 'left', borderRadius: 5}}
        onClick={handleOpen}
        title={<Typography variant='h4'>See who's coming</Typography>}
        subheader={firstFewStr}
        action={avatars.length > 0 ? <AvatarsGrouped event_id={entity.id} entities={avatars}/> : null}
    />
};

export default InvitesSummary;
