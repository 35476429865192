import React, {ChangeEvent, useState} from 'react';
import {Typography} from "@mui/material";
import {Invites} from "../object-actions/types/types";
import Avatar from "@mui/material/Avatar";
import CardHeader, {CardHeaderProps} from "@mui/material/CardHeader";
import {useNavigate} from "react-router-dom";
import {getFullName, getUsername, makeAbsolute} from "../utils";
import {FriendshipRelButton} from "../forms/FriendshipButton";
import {InviteButton} from "../forms/StyledFields";
import ApiClient from "../config/ApiClient";
import {Check, Circle, Close} from "@mui/icons-material";

interface InviteProps {
    entity: Invites;
}

const InviteSent: React.FC<InviteProps> = ({entity}) => {
    const navigate = useNavigate();
    const [hide, setHide] = useState<boolean>(false);

    const headerProps: Partial<CardHeaderProps> = {};

    const recipient = entity['recipient']

    const handleStatusChange = async (value:string) => {

        const response = await ApiClient.patch(`/api/invites/${entity.id}`, {status: value});
        if (response.success && response.data) {
            console.log(response.data);
            setHide(true)
        }
        if (response.error) {
            alert(response.error)
        }
    };

    if (hide) return null;

    headerProps.avatar =
        <Avatar src={typeof recipient.img === 'string' ? makeAbsolute(recipient.img) : recipient.str}
                alt={recipient['str']}
                onClick={() => navigate(`/users/${recipient.id}`)}/>

    headerProps.title = getFullName(recipient)
    headerProps.subheader = getUsername(recipient)

    headerProps.title = <Typography variant={'subtitle1'}>{getFullName(recipient)} {entity?.checkin &&
                    <Circle color={'success'} sx={{fontSize: 10, verticalAlign: 'baseline'}}/>}</Typography>
    headerProps.subheader =
        <Typography variant={'body2'}>invited by <em>{getFullName(entity['author'])}</em></Typography>

    if (entity.status === 'referred' || entity.status === 'requested') {
        headerProps.action = <div style={{marginTop:15}}>
            <InviteButton
                sx={{marginRight:2,  borderColor:'text.disabled'}}
                variant='outlined'
                color={'inherit'}
                size={'large'}
                value={'declined'}
                onClick={() => handleStatusChange('declined')}
            >
                <Close color={'disabled'} />
            </InviteButton>
            <InviteButton
                variant='outlined'
                color={'primary'}
                size={'large'}
                onClick={() => handleStatusChange('accepted')}
            >
                <Check  />
            </InviteButton>
        </div>
    } else if (entity.friendship) {
        headerProps.action = <FriendshipRelButton friendship={entity.friendship} recipient={recipient}/>
    } else {
        headerProps.action = <FriendshipRelButton recipient={recipient}/>
    }


    return <CardHeader {...headerProps} />
}

export default InviteSent;
